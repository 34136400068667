import _ from "lodash";

export function arrayToObject(array, originalObj, value) {
  var newObj = originalObj;
  for (var i = 0; i < array.length; ++i) {
    let languageCode = array[i]
    if (newObj[languageCode] === undefined) newObj[languageCode] = _.isObject(value) ? (value[languageCode] || value?.en) || "" : value || "";
  }
  return newObj;
}


export function formatPageData({ contentData, languagesList, defaultLanguage, themeConfig }) {
  const languagesCodes = languagesList.map(l => l?.code);
  return {
    ...themeConfig,
    style: contentData?.style,
    languages: languagesList,
    defaultLanguage: defaultLanguage || "en",
    selectedLanguage: defaultLanguage || "en",
    mainMenu: contentData?.mainMenu?.map((menuItem, index) => {
      return {
        ...menuItem,
        title: arrayToObject(languagesCodes, _.isObject(menuItem?.title) ? menuItem?.title : {}, themeConfig.mainMenu[index]?.title_en),
        subNavs: menuItem?.subNavs?.map(sub => ({
          ...sub,
          title: arrayToObject(languagesCodes, _.isObject(sub?.title) ? sub?.title : {}, ""),
        }))
      }
    }),
    hero: {
      ...contentData?.hero,
      mainContent: {
        ...contentData?.hero?.mainContent,
        title: arrayToObject(languagesCodes, _.isObject(contentData?.hero?.mainContent?.title) ? contentData?.hero?.mainContent?.title : {}, themeConfig.hero.mainContent.title),
        body: arrayToObject(languagesCodes, _.isObject(contentData?.hero?.mainContent?.body) ? contentData?.hero?.mainContent?.body : {}, themeConfig.hero.mainContent.body),
      }
    },
    ourServices: {
      ...themeConfig?.ourServices,
      title: arrayToObject(languagesCodes, _.isObject(contentData?.ourServices?.title) ? contentData?.ourServices?.title : {}, contentData?.ourServices?.title),
      body: arrayToObject(languagesCodes, _.isObject(contentData?.ourServices?.body) ? contentData?.ourServices?.body : {}, contentData?.ourServices?.body),
      services: contentData?.ourServices?.services?.map((service, index) => ({
        ...service,
        header: arrayToObject(languagesCodes, _.isObject(service?.header) ? service?.header : {}, contentData?.ourServices?.services[index].header),
        body: arrayToObject(languagesCodes, _.isObject(service?.body) ? service?.body : {}, contentData?.ourServices?.services[index].body),
      }))
    },
    // page content
    content: contentData?.content?.map((item, index) => {
      const currentContentData = contentData.content[index];
      if (item?.type === "aboutUs") {
        return {
          ...item,
          imageFirst: themeConfig?.content?.find(i => i?.type === "aboutUs").imageFirst,
          items: item?.items?.map((current) => current?.id === "about-us-content"
            ? {
              ...current,
              head: arrayToObject(languagesCodes, _.isObject(current?.head) ? current?.head : {}, ""),
              body: arrayToObject(languagesCodes, _.isObject(current?.body) ? current?.body : {}, "")
            }
            : { ...current })
        }
      }
      else if (item?.type === "faq") {
        return {
          ...item,
          title: arrayToObject(languagesCodes, _.isObject(item?.title) ? item?.title : {}, currentContentData?.title),
          body: arrayToObject(languagesCodes, _.isObject(item?.body) ? item?.body : {}, currentContentData?.body),
          faqs: item?.faqs?.map((faqItem, faqIndex) => {
            return {
              ...faqItem,
              question: arrayToObject(languagesCodes, _.isObject(faqItem?.question) ? faqItem?.question : {}, currentContentData?.faqs[faqIndex]?.question),
              body: arrayToObject(languagesCodes, _.isObject(faqItem?.body) ? faqItem?.body : {}, currentContentData?.faqs[faqIndex]?.body),
            }
          })
        }
      }
      return {
        ...item,
        title: arrayToObject(languagesCodes, _.isObject(item?.title) ? item?.title : {}, currentContentData?.title),
        body: arrayToObject(languagesCodes, _.isObject(item?.body) ? item?.body : {}, currentContentData?.body),
        items: contentData?.content?.[index]?.items,
      }
    }),

    pagesData: contentData.pagesData?.map((page, index) => {
      const mainHeader = arrayToObject(languagesCodes, _.isObject(page?.mainHeader) ? page?.mainHeader : {}, contentData.pagesData[index].mainHeader || themeConfig.pagesData[index].mainHeader);
      if (page?.id !== "about-page") {
        return {
          ...page,
          content: contentData?.pagesData?.[index]?.content,
          about: {
            ...page.about,
            items: page?.about?.items?.map((current, aboutItemIndex) => current?.id === "about-us-content"
              ? {
                ...current,
                body: arrayToObject(
                  languagesCodes,
                  _.isObject(current?.body) ? current?.body : {},
                  contentData?.pagesData[index]?.about?.items[aboutItemIndex]?.body
                )
              }
              : { ...current })
          },
          mainHeader
        }
      }
      return {
        ...page,
        mainHeader,
      }
    })
  }
}


export function addingServiceToContent(content = [], newServices = [], languagesList) {
  const toursSectionIndex = content.findIndex(i => i?.type === "tours");
  const languagesCodes = languagesList.map(l => l?.code);
  for (let i = 0; i < newServices.length; i++) {
    const service = newServices[i];
    const contentHasServiceType = content?.find(i => i?.type === service.type);
    if (!contentHasServiceType && toursSectionIndex > -1) {
      content.splice(toursSectionIndex + 1, 0, {
        "type": `${service.type}`,
        "title": arrayToObject(languagesCodes, _.isObject(service?.title) ? service?.title : {}, service?.title),
        "body": arrayToObject(languagesCodes, _.isObject(service?.title) ? service?.title : {}, service?.title),
        "id": `${service.type}-section`,
        "items": []
      });
    }
  }
  return content;
}