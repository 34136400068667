import { useSBSState } from 'context/global';
import React, { useState } from "react";
import { uploadFile } from "services/auth";
import { ReactComponent as CloseIcon } from "assets/images/product-builder/close-circle.svg";
import { uuidv4 } from 'modules/WebBuilder/shared/DragAndDropContainer';
import { handleResponesNotification } from 'modules/UmrahOperations/helper/accountingHelpers';

export default function UploadNewImage({ handleUpload, slider, setSlider, handleSliderImages, toggle, hasSlider }) {

	const { companyInfo } = useSBSState();

	const [imageURL, setImageURL] = useState("");


	function validateFileForUpload(file) {
		if (!file) return;
		const maxSizeInBytes = 1 * 1024 * 1024; // 1MB in bytes
		const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];

		if (!allowedTypes.includes(file.type)) {
			return `Invalid file type: ${file.type}. Only PNG, JPEG, and JPG are allowed.`;
		}

		if (file.size > maxSizeInBytes) {
			return "File size exceeds 1MB.";
		}

		return true;
	}

	function handleFileSelection(e, source) {
		const files = source === "drop" ? e.dataTransfer.files : e.target.files;
		let filesReadyToUpload = [];
		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			const fileMatchUploadRules = validateFileForUpload(file);

			if (fileMatchUploadRules === true) {
				const objectURL = URL.createObjectURL(file);
				filesReadyToUpload.push({ id: uuidv4(), file, previewBlob: objectURL });
			} else {
				handleResponesNotification({
					alertType: "danger",
					message: `${file.name} ${fileMatchUploadRules}`,
					title: "Invalid file type or size"
				});
				return
			}
		}
		filesReadyToUpload = hasSlider ? [...slider, ...filesReadyToUpload] : filesReadyToUpload
		setSlider(filesReadyToUpload);
	}


	function handleDragOver(event) {
		event.preventDefault();
	};

	async function upload(file) {
		if (!companyInfo?.id) return;
		const formData = new FormData();
		formData.append("file", file);
		formData.append("name", file?.name);
		formData.append("bucket", `${companyInfo?.id}-webBuilder`);
		const response = await uploadFile(formData);
		if (response.data.data.uuid) {
			return response.data.data.uuid;
		}
	};


	async function uploadImages() {
		const URI = process.env.REACT_APP_FILEUPLOAD_URL
		for (let i = 0; i < slider.length; i++) {
			const element = slider[i];
			if ("file" in element) {
				const uuid = await upload(element.file);
				if (uuid) {
					const fileUrl = `${URI}/fetch/${uuid}`;
					slider[i].fileUrl = fileUrl;
				}
			}
		}
		const newImages = slider.map((image) => image.fileUrl || image);
		handleSliderImages(newImages);
		toggle();
	};

	function handleRemoveImage(id) {
		if (!id) return;
		const newImages = slider.filter((image) => image.id !== id);
		setSlider(newImages);
	}


	function insertImageURL() {
		const regex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
		const isValidURL = regex.test(imageURL);
		if (imageURL && isValidURL) {
			handleUpload(imageURL)
		} else {
			handleResponesNotification({ alertType: "danger", message: "Please enter valid URL", title: "Invalid URL" });
		}
	};

	return (
		<div className="p-4 bg-white">

			<div className="py-4 px-3 tools-main-upload m-modal-2"
				onDragOver={handleDragOver}
				onDrop={(e) => handleFileSelection(e, "drop")}
			>
				<h3 className="tools-header-primary m-modal-1">Drag File here</h3>
				<h3 className="tools-header-secondary m-modal-1">OR</h3>

				<div className="tools-header-button m-modal-1">
					<label htmlFor={`image-hero-upload-file`} className="mb-0">{"Browse your Computer"}</label>
					<input
						onChange={(e) => handleFileSelection(e, "file")}
						style={{ display: "none" }}
						type="file"
						id={`image-hero-upload-file`}
						accept={"image/png, image/jpeg, image/jpg"}
						multiple={hasSlider}
					/>
				</div>

				<p className="d-flex flex-column h6 text-center mt-3 gap-10" style={{ color: "#707170" }}>
					<span>Only jpeg, png are accepted</span>
					<span>Dimensions 1900px * 600px /  Maximum size 1MB</span>
				</p>

				{slider?.length > 0 &&
					<div className="p-3 mt-4 bg-gray rounded-lg w-100" style={{ maxHeight: "500px", overflow: "auto" }}>
						<PreviewUploadedImages images={slider} handleRemoveImage={handleRemoveImage} />
					</div>
				}

				{!hasSlider &&
					<div className="mt-3">
						<h3 className="tools-header-secondary m-modal-1">OR</h3>
						<div className="tools-input m-modal-1">
							<input
								placeholder="Enter URL to upload from web"
								value={imageURL}
								onChange={(e) => {
									setImageURL(e?.target?.value)
								}}
							/>
							<button onClick={insertImageURL} className="ml-2 tools-header-button">
								Done
							</button>
						</div>
					</div>
				}

			</div>

			<div className="d-flex justify-content-end p-1">
				<button
					className="btn mt-2 px-5 py-2"
					onClick={uploadImages}
					// onClick={getBucket}
					style={{
						background: "#292d32",
						color: "#FFF",
						fontSize: "1rem",
					}}
					disabled={(hasSlider && slider?.length <= 1) || (!hasSlider && slider.length === 0)}
				>
					Save
				</button>
			</div>
		</div>
	);
};



function PreviewUploadedImages({ images = [], handleRemoveImage }) {
	return (
		<div className="row w-100 mx-0 align-items-center">
			{images.map(image => {
				const imgSrc = image?.previewBlob ? image?.previewBlob : image
				return (
					<div className="col-md-4 mb-2 position-relative overflow-hidden" key={image?.id || image}>
						<span className="position-absolute bg-white p-1 pointer" style={{ insetInlineEnd: "0" }} onClick={() => handleRemoveImage(image?.id)}>
							<CloseIcon />
						</span>
						<img src={imgSrc} alt="" className="img-fluid rounded-lg" style={{ objectFit: "cover", maxWidth: "100%", minHeight: "160px" }} />
					</div>
				)
			})}
		</div>
	)
}
