import { useEffect, useState } from 'react';
import SelectField from 'components/Form/SelectField/SelectField';
import TextField from 'components/Form/TextField/TextField';
import validate from 'helpers/validate';
import Locale from 'translations';
import { useSBSState } from 'context/global'
import UploadFile from 'components/UploadFile';
import generateUniqueID from 'helpers/generateUniqueID';
import { createFlight, createTransporter } from 'services/requests';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

export default function RequestSharedOtherForm({ type }) {
  const history = useHistory();
  const { requests } = Locale;
  const [service, setService] = useState({
    name: '',
    _country_id: '',
    country_id: '',
    logo: ''
  });
  const [errors, setErrors] = useState({
    name: { required: true, touched: false },
    logo: { required: true, touched: false },
    country_id: { required: true, touched: false },
  });
  const { allCountries } = useSBSState();
  const formValid = () => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length) {
      return errorKeys.every(k => !errors[k]?.required);
    }
    return true;
  }

  const markAllAsTouched = () => {
    setErrors((prevErrors) => {
      const updatedErrors = {};
      for (const key in prevErrors) {
        if (prevErrors.hasOwnProperty(key)) {
          updatedErrors[key] = {
            ...prevErrors[key],
            touched: true,
          };
        }
      }
      return updatedErrors;
    });
  }

  const save = async (event) => {
    event.preventDefault();
    if (!formValid()) {
      markAllAsTouched();
      return;
    }
    let res;
    if (type === 'flights') {
      res = await createFlight({ ...service });
    }
    else if (type === 'transporters') {
      res = await createTransporter({ ...service });
    }
    if (res?.status === 200 || res?.status === 201) {
      history.push(`/requests/${type}`)
    }
  }

  useEffect(() => {
    console.log(service)
  }, [service])

  return (
    <form onSubmit={save}>
      <div className="container-fluid">
        <div className="row g-4">
          <div className="col-md-6 mb-2">
            <TextField
              withStar={true}
              type="text"
              hasLabel={true}
              label={type === 'flights' ? requests.flightName : requests.transporterName}
              placeholder={requests?.typeName?.[type]}
              name="name"
              id="name"
              value={service?.name}
              onBlur={() => {
                setErrors({
                  ...errors,
                  name: {
                    ...errors.name,
                    touched: true
                  }
                })
              }}
              onChange={(e) => {
                setService({
                  ...service,
                  name: e?.target?.value
                });
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "name", value: e?.target?.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.name?.required && errors?.name?.touched ? "danger" : ""}
              errors={errors?.name?.touched ? errors?.name : null}
            />
          </div>
          <div className="col-md-6 mb-2">
            <SelectField
              id="country_id"
              name="country_id"
              withStar={true}
              hasLabel={true}
              label={requests.country}
              options={allCountries}
              value={service?._country_id}
              onBlur={() => {
                setErrors({
                  ...errors,
                  country_id: {
                    ...errors.country_id,
                    touched: true
                  }
                })
              }}
              onChange={(e) => {
                setService({
                  ...service,
                  _country_id: e.label,
                  country_id: e.value
                })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "country_id", value: e.value },
                    { length: true }
                  ),
                });
              }}
              color={errors.country_id?.required && errors.country_id?.touched ? "danger" : ""}
              errors={errors.country_id?.touched ? errors.country_id : null}
            />
          </div>
          <div className="col-12 mb-4">
            <label className='fw-500 mb-1'>
              {requests?.addLogo}
              <span className="text-danger">*</span>
            </label>
            <UploadFile
              id={`upload-service-image-${generateUniqueID()}`}
              onChange={(images) => {
                setService({
                  ...service,
                  logo: images?.length ? images[0] : ''
                })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "logo", value: images?.length ? images[0] : '' },
                    { required: true }
                  ),
                });
              }}
              errorMsg={
                !service?.logo && errors?.logo?.touched
                  ? requests?.serviceImageRequired
                  : null
              }
              value={service?.logo ? [service.logo] : []}
            />

          </div>
          <div className="col-12 d-flex justify-content-end">
            <button type='submit' className='btn-Product-Filter bg-nxt'>{requests.saveRequest}</button>
          </div>
        </div>
      </div>
    </form>
  )
}