import { useEffect, useState } from 'react'
// React-Router-Dom
import { useParams } from 'react-router-dom'
// Transaltion
import Locale from 'translations'
// Components
import SelectField from 'components/Form/SelectField/SelectField'
// Shared Components
import LanguagesModal from './shared/LanguagesModal'
import HotelTranslate from './shared/HotelTranslate'
import FlightTranslate from './shared/FlightTranslate'
import AttractionTranslate from './shared/AttractionTranslate'
import TransferTranslate from './shared/TransferTranslate'
import OthersTranslate from './shared/OthersTranslate'
import PackageTranslate from './shared/PackageTranslate'
// Hooks
import useSharedData from './constant/useSharedData'
// Assets
import { ReactComponent as AddIcon } from 'assets/images/serviceBuilder/orange_add.svg'
// Services
import { addServiceLanguages, getServicesById } from 'services/serviveBuilder'
//-------------------------------------------------------------------------------------
function ServicesTranslate() {

  // *** hooks
  const { Service_Builder } = Locale

  const { serviceName, id } = useParams()

  const {
    languageModalState,
    changeLanguageModalState,
  } = useSharedData()

  // *** States
  const [selectedLanguage, setSelectedLanguage] = useState({
    name: 'EN',
    id: 'en',
    code: 'en'
  })

  const [serviceData, setServiceData] = useState(null)
  const [languages, setLanguages] = useState([])

  // *** Functions
  const fetchServiceData = async () => {
    const response = await getServicesById(id)
    if (response?.status === 200) {
      setServiceData(response?.data?.data?.service)
      // if (response?.data?.data?.langs?.length) {
      changeLanguageModalState('setLanguages', response?.data?.data?.langs || [])
      // }
      const LanguagesList = response?.data?.data?.langs?.map(lang => ({
        name: lang?.toUpperCase(),
        id: lang,
        code: lang
      }))
      setLanguages(LanguagesList)
    }
    return response
  }

  const saveLanguages = async () => {
    const res = await addServiceLanguages(id, { langs: languageModalState?.selectedLangs })
    if (res?.status === 200) {
      fetchServiceData().then(() => changeLanguageModalState('toggle'))
    }
  }

  useEffect(() => {
    fetchServiceData()
  }, [id])

  // console.log(serviceData)
  // console.log(languageModalState)

  // ------- JSX Code -------
  return (
    <div className=''>

      {/* TranslateServiceHeader */}
      <div className='row mx-auto align-items-center service-builder-holder mb-4'>

        <h3 className='col-12 col-md-6 title'  >
          {Service_Builder?.translateForLanguages}
        </h3>

        <div className='col-12 col-md-6 row align-items-center'>

          <div className='w-100  col-12 col-md-9'>
            <SelectField
              hasLabel={false}
              label={Service_Builder?.selectLanguage}
              placeholder={Service_Builder?.selectLanguage}
              id="languages"
              name="languages"
              value={selectedLanguage?.name}
              options={languages}
              onChange={(e) => setSelectedLanguage(e)}
            />
          </div>

          <button
            type='button'
            onClick={() => changeLanguageModalState('toggle')}
            className="col-12 col-md-3 submit-addNew-btn d-flex align-items-center justify-content-center"
          >
            <AddIcon style={{ color: '#d4a655' }} />
            <span className="text d-flex align-items-center gap-10 justify-content-center">
              {Service_Builder?.language}
            </span>
          </button>
        </div>


      </div>

      {serviceName === 'hotel' &&
        <HotelTranslate
          data={serviceData}
          setData={setServiceData}
          selectedLang={selectedLanguage?.code}
        />
      }

      {serviceName === 'flight' &&
        <FlightTranslate
          // flightData={serviceData}
          // setFlightData={setServiceData}
          // selectedLang={selectedLanguage?.code}
          data={serviceData}
          setData={setServiceData}
          selectedLang={selectedLanguage?.code}
        />
      }

      {serviceName === 'attraction' &&
        <AttractionTranslate
          data={serviceData}
          setData={setServiceData}
          selectedLang={selectedLanguage?.code}
        />
      }

      {serviceName === 'transfer' &&
        <TransferTranslate
          data={serviceData}
          setData={setServiceData}
          selectedLang={selectedLanguage?.code}
        />
      }

      {serviceName === 'other' &&
        <OthersTranslate
          data={serviceData}
          setData={setServiceData}
          selectedLang={selectedLanguage?.code}
        />
      }

      {serviceName === 'packages' &&
        <PackageTranslate
          data={serviceData}
          setData={setServiceData}
          selectedLang={selectedLanguage?.code}
        />
      }

      {/* Add Language Modal */}
      <LanguagesModal
        isOpen={languageModalState?.isOpen}
        toggle={() => changeLanguageModalState('toggle')}
        setLanguages={(languages) => changeLanguageModalState('setLanguages', languages)}
        languageModalState={languageModalState}
        sendLanguages={saveLanguages}
      />

    </div>
  )
}

export default ServicesTranslate