import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import Locale from 'translations';
import { useWebBuilderState } from 'context/webBuilder';
import useWBHeader from './useWBHeader';


export default function WebBuilderNav() {
  const { webBuilder } = Locale;
  const history = useHistory();
  const { bundle } = useParams();

  const { selectedLanguage } = useWebBuilderState();
  const {
    isPreview,
    visibleMenuItems
  } = useWBHeader();


  return (
    <ul className="main-nav">
      <li
        onClick={() => {
          if (!isPreview) return
          history.push(`/${bundle}/web-builder-v2/preview`);
        }}
      >
        {webBuilder.home}
      </li>

      {visibleMenuItems?.map(item => {
        return (
          <li key={item?.id} className="position-relative"
            onClick={() => {
              if (!item?.subNavs || item?.subNavs?.length === 0) {
                if (!isPreview) return;
                history.push({ pathname: `/${bundle}/web-builder-v2/preview/${item?.url}` });
              }
            }}
          >
            {item?.subNavs?.length > 0 ? null : <span>{item?.title?.[selectedLanguage] || item?.title?.["en"]}</span>}
            {/********************************************** sub navs *************************************************************/}
            {item?.subNavs?.length > 0 &&
              <UncontrolledDropdown>
                <DropdownToggle caret className={`service-dropdown`}>
                  {item?.title?.[selectedLanguage] || item?.title?.["en"]}
                </DropdownToggle>

                <DropdownMenu right>
                  {item?.subNavs.map((subNav) =>
                    <DropdownItem key={subNav.id}
                      onClick={() => {
                        if (!isPreview) return
                        history.push({ pathname: `/${bundle}/web-builder-v2/preview/${subNav?.url}` });
                      }}
                    >
                      {subNav?.title?.[selectedLanguage] || subNav?.title?.["en"]}
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
            }
          </li>
        )
      })}


    </ul>
  )
}