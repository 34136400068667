import { useEffect, useState } from 'react'
// React-Router-Dom
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
// Translation
import Locale from 'translations';
// Components
import SelectField from 'components/Form/SelectField/SelectField';
import TextField from 'components/Form/TextField/TextField';
import TextAreaField from 'components/Form/TextAreaField/TextAreaField';
import UploadFile from 'components/UploadFile';
// Context
import { useSBSState } from 'context/global';
// Servicies
import { fetchCities } from 'services/lookups';
// Helper
import validate from 'helpers/validate';
import generateUniqueID from 'helpers/generateUniqueID';
// Custom Hook
import useSharedData from '../constant/useSharedData';
import useDidMountEffect from 'hooks/useDidMountEffect ';
// ------------------------------------------------------------------------------------------------------------


const MiniTransferForm = ({ data, setData, packageType, isSubmit }) => {

  // *** Hooks 
  const { Service_Builder, inventory, commons } = Locale

  const { allCountries } = useSBSState()

  const { status } = useParams()

  const isUmrah = packageType === 'Hajj' || packageType === 'Umrah' || packageType === 'Umrah plus'

  const {
    cyclesOptions,
    driverOptions,
    refundOptions,
    requiredTourismInputs,
    requiredUmrahInputs,
    vehiclesModel,
    vehicleTypesOptions
  } = useSharedData()

  // *** State 
  const [citiesList, setCitiesList] = useState([])

  const [errors, setErrors] = useState({});

  const [transferData, setTransferData] = useState(data);

  const requiredInputs = transferData?.trip_type === 'umrah' ? requiredUmrahInputs : requiredTourismInputs;

  function handleTransferInputsChanges({ key, value }) {
    let transferDataClone = { ...transferData }
    if (key === 'country') {
      fetchCity(value?.id)
    }
    transferDataClone[`${key}`] = value;
    setTransferData({ ...transferDataClone })
  }

  function checkFormErrors() {
    let submitError = {};
    requiredInputs?.filter((inputName) => inputName !== 'terms_en')?.forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: transferData[key] },
          { required: true }
        )
      }
    })
    setErrors(() => submitError);
    return submitError;
  }

  const fetchCity = async (countryId) => {
    const citiesRes = await fetchCities(countryId);
    setCitiesList(citiesRes);
  };

  useDidMountEffect(() => {
    if (isSubmit) {
      checkFormErrors();
    }
    setData(transferData)
  }, [transferData, isSubmit])

  console.log(errors)
  console.log(transferData)

  // ---------- JSX Code --------
  return (
    <div >
      <form>
        {/* Transfer Service Form  */}
        <div className='row form-holder mx-0 border-0'>

          {/* Trip Type Umrah */}
          <div className="col-2 mt-2">
            <label
              htmlFor={`umrah`}
              className={`${transferData.trip_type === 'umrah' ? 'active-tour-tab' : 'tour-tab'} pointer-event`}
            >
              <input
                type="radio"
                id={`umrah`}
                name={`trip_type`}
                value="umrah"
                checked={transferData?.trip_type === 'umrah'}
                onChange={(e) => {
                  handleTransferInputsChanges({ key: 'trip_type', value: e?.target?.value })
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "trip_type", value: e?.target?.value },
                      { required: true }
                    ),
                  });
                }}
                color={errors?.trip_type?.required ? "danger" : ""}
                errors={errors?.trip_type}
              />
              <p className='taxes-type-value'> {Service_Builder?.umrah}</p>
            </label>
          </div>

          {/* Trip Type Tour */}
          <div className="col-2 custom-field mt-2">
            <label
              htmlFor={`tourism`}
              className={`${transferData.trip_type === 'tourism' ? 'active-tour-tab' : 'tour-tab'}`}
            >
              <input
                type="radio"
                id={`tourism`}
                disabled={isUmrah}
                name={`trip_type`}
                value="tourism"
                checked={transferData?.trip_type === 'tourism'}
                onChange={(e) => {
                  handleTransferInputsChanges({ key: 'trip_type', value: e?.target?.value })
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "trip_type", value: e?.target?.value },
                      { required: true }
                    ),
                  });
                }}
                color={errors?.trip_type?.required ? "danger" : ""}
                errors={errors?.trip_type}
              />
              <p className='taxes-type-value'>  {Service_Builder?.tourism}</p>
            </label>
          </div>

          <div className="col-8 mt-2" />

          {/* Service Name en */}
          <div className="col-6 custom-field mt-2">
            <TextField
              type="text"
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.service_name_en}
              placeholder={Service_Builder?.service_name_en}
              name="name_en"
              id="name_en"
              value={transferData?.name_en}
              onChange={(e) => {
                handleTransferInputsChanges({ key: 'name_en', value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "name_en", value: e.target.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.name_en?.required ? "danger" : ""}
              errors={errors?.name_en}
            />
          </div>

          {/* Cycle */}
          {transferData?.trip_type === 'umrah' &&
            <div className="col-6 mt-2">
              <SelectField
                hasLabel={status === 'edit' ? true : false}
                label={Service_Builder?.cycle}
                placeholder={Service_Builder?.cycle}
                id="cycle"
                name="cycle"
                value={transferData?.cycle?.name}
                options={cyclesOptions}
                onChange={(e) => {
                  handleTransferInputsChanges({ key: 'cycle', value: e })
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "cycle", value: e },
                      { required: true }
                    ),
                  });
                }}
                color={errors?.cycle?.required ? "danger" : ""}
                errors={errors?.cycle}
              />
            </div>
          }

          {/* Country */}
          {transferData?.trip_type === 'tourism' &&
            <div className="col-3 mt-2">
              <SelectField
                hasLabel={status === 'edit' ? true : false}
                label={Service_Builder?.country}
                placeholder={Service_Builder?.country}
                id="country"
                name="country"
                value={transferData?.country?.name}
                options={allCountries}
                onChange={(e) => {
                  handleTransferInputsChanges({ key: 'country', value: e })
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "country", value: e },
                      { required: true }
                    ),
                  });
                }}
                color={errors?.country?.required ? "danger" : ""}
                errors={errors?.country}
              />
            </div>
          }

          {/* City */}
          {transferData?.trip_type === 'tourism' &&
            <div className="col-3 mt-2">
              <SelectField
                disabled={!transferData?.country?.name}
                hasLabel={status === 'edit' ? true : false}
                label={Service_Builder?.city}
                placeholder={Service_Builder?.city}
                id="city"
                name="city"
                value={transferData?.city?.name}
                options={citiesList}
                onChange={(e) => {
                  handleTransferInputsChanges({ key: 'city', value: e })
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "city", value: e },
                      { required: true }
                    ),
                  });
                }}
                color={errors?.city?.required ? "danger" : ""}
                errors={errors?.city}
              />
            </div>
          }

          {/* Type */}
          <div className="col-4 mt-2">
            <SelectField
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.type}
              placeholder={Service_Builder?.type}
              id="vehicleType"
              name="vehicleType"
              value={transferData?.vehicleType?.name}
              options={vehicleTypesOptions}
              onChange={(e) => {
                handleTransferInputsChanges({ key: 'vehicleType', value: e })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "vehicleType", value: e },
                    { required: true }
                  ),
                });
              }}
              color={errors?.vehicleType?.required ? "danger" : ""}
              errors={errors?.vehicleType}
            />
          </div>

          {/* Model */}
          <div className="col-4 mt-2">
            <SelectField
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.model}
              placeholder={Service_Builder?.model}
              id="model"
              name="model"
              value={transferData?.model?.name}
              options={vehiclesModel}
              onChange={(e) => {
                handleTransferInputsChanges({ key: 'model', value: e })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "model", value: e },
                    { required: true }
                  ),
                });
              }}
              color={errors?.model?.required ? "danger" : ""}
              errors={errors?.model}
            />
          </div>

          {/*Transfer Car Capacity  */}
          <div className="col-4 custom-field mt-2">
            <TextField
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.car_capacity}
              placeholder={Service_Builder?.car_capacity}
              name="car_capacity"
              id="car_capacity"
              type="number"
              value={transferData?.car_capacity}
              onChange={(e) => {
                handleTransferInputsChanges({ key: 'car_capacity', value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "car_capacity", value: e.target.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.car_capacity?.required ? "danger" : ""}
              errors={errors?.car_capacity}
            />
          </div>

          {/* Transfer With Driver  */}
          <div className="col-4 mt-2">
            <SelectField
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.driver}
              placeholder={Service_Builder?.driver}
              id="withDriver"
              name="withDriver"
              value={transferData?.withDriver?.name}
              options={driverOptions}
              onChange={(e) => {
                handleTransferInputsChanges({ key: 'withDriver', value: e })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "withDriver", value: e },
                    { required: true }
                  ),
                });
              }}
              color={errors?.withDriver?.required ? "danger" : ""}
              errors={errors?.withDriver}
            />
          </div>

          {/* Transfer Refund*/}
          <div className="col-4 mt-2 align-self-baseline">
            {/* <SelectField
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.refundType}
              placeholder={Service_Builder?.refundType}
              id="refundType"
              name="refundType"
              value={transferData?.refundable?.name}
              options={refundOptions}
              onChange={(e) => {
                handleTransferInputsChanges({ key: 'refundable', value: e })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "refundable", value: e },
                    { required: true }
                  ),
                });
              }}
              color={errors?.refundable?.required ? "danger" : ""}
              errors={errors?.refundable}
            /> */}
          </div>

          {/* Transfer Description*/}
          <div className="col-6 custom-field mt-2">
            <TextAreaField
              hasLabel={status === 'edit' ? true : false}
              height={'126px'}
              label={Service_Builder?.description}
              placeholder={Service_Builder?.description}
              value={transferData?.description_en}
              onChange={(e) => {
                handleTransferInputsChanges({ key: 'description_en', value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "description_en", value: e?.target?.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.description_en?.required ? "danger" : ""}
              errors={errors?.description_en}
            />
          </div>

          {/* Transfer Policy*/}
          <div className="col-6 custom-field mt-2">
            <TextAreaField
              hasLabel={status === 'edit' ? true : false}
              height={'126px'}
              label={Service_Builder?.policy}
              placeholder={Service_Builder?.policy}
              value={transferData?.policy_en}
              onChange={(e) => {
                handleTransferInputsChanges({ key: 'policy_en', value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "policy_en", value: e?.target?.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.policy_en?.required ? "danger" : ""}
              errors={errors?.policy_en}
            />
          </div>

          {/* Transfer Iamges*/}
          <div className="col-12 custom-field mt-2">
            <div className='images-holder d-flex  align-items-center '>
              <UploadFile
                id={`upload-hotel-image-${generateUniqueID()}`}
                label={Service_Builder?.add_images}
                value={transferData.images}
                onChange={(images) => {
                  handleTransferInputsChanges({ key: "images", value: images })
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "images", value: images.length },
                      { required: true }
                    ),
                  });
                }}
                errorMsg={
                  errors?.images?.required
                    ? inventory?.viewReservation?.Photos + " " + commons?.isRequired
                    : false
                }
              />
            </div>
          </div>

        </div>
      </form>
    </div>
  )
}

export default MiniTransferForm