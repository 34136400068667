import { useEffect, useState } from 'react';
// React-Router-Dom
import { useHistory, useParams } from 'react-router-dom';
// Translations
import Locale from 'translations'
// Lodash
import _ from "lodash";
// Assets
import { ReactComponent as MagicIcon } from 'assets/images/serviceBuilder/magic-translate.svg'
// Context
// import { useSBSState } from 'context/global';
// Components
import TextField from 'components/Form/TextField/TextField';
import TextAreaField from 'components/Form/TextAreaField/TextAreaField';
// Custom Hooks
import useSharedData from '../constant/useSharedData';
import useDidMountEffect from 'hooks/useDidMountEffect ';
// Helpler
import validate, { isFormValid } from 'helpers/validate';
// Services
import { addServiceTranslate } from 'services/serviveBuilder';
// -------------------------------------------------------------------------------------------------


function FlightTranslate({ data, setData, selectedLang }) {

  // *** hooks
  const { Service_Builder } = Locale

  const history = useHistory()

  const { serviceName } = useParams()

  const { handleAutoTranslate } = useSharedData()

  // const { locale } = useSBSState();

  // *** States
  const [errors, setErrors] = useState({});

  const [flightData, setFlightData] = useState(data);

  const [formData, setFormData] = useState();

  const requiredInputs = [
    `name_${selectedLang}`,
    `flight_description_${selectedLang}`,
    `flight_terms_${selectedLang}`,
  ]

  // *** Fuctions
  // const handleFlightInputsChanges = ({ key, value }) => {
  //   let flightDataClone = _.cloneDeep(flightData)
  //   flightDataClone[`${key}`] = value
  //   setFlightData({ ...flightDataClone })
  // }

  const handleFlightInputsChanges = ({ key, value }) => {
    let formDataClone = _.cloneDeep(formData)
    formDataClone[`${key}`] = value
    setFormData({ ...formDataClone })
  }

  function checkFormErrors() {
    let submitError = {};
    requiredInputs.forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: formData[key] },
          { required: true }
        )
      }
    })
    setErrors(() => submitError);
    return submitError;
  }

  async function submit(e) {
    e.preventDefault();
    let formErrors = checkFormErrors();
    if (!isFormValid(formErrors)) {
      return
    }
    let requestBody = {
      name: { [selectedLang]: formData?.[`name_${selectedLang}`] },
      description: { [selectedLang]: formData?.[`flight_description_${selectedLang}`] },
      terms: { [selectedLang]: formData?.[`flight_terms_${selectedLang}`] },
    }
    // console.log(requestBody)
    const response = await addServiceTranslate(flightData?.id, 'flights', requestBody);
    if ((response?.status === 200 || response?.status === 201)) {
      history.push('/website/service-builder')
    }
  }


  const showFormData = () => {
    const transformedData = {
      [`name_${selectedLang}`]: data?.name?.[selectedLang],
      [`flight_description_${selectedLang}`]: data?.description?.[selectedLang],
      [`flight_terms_${selectedLang}`]: data?.terms?.[selectedLang],
    }
    setFormData(transformedData)
  }

  useDidMountEffect(() => {
    setData({ ...flightData, ...formData })
  }, [serviceName === 'flight' ? null : formData])

  useEffect(() => {
    setFlightData(data)
    showFormData()
  }, [serviceName === 'flight' ? data : null, selectedLang])

  // console.log(flightData)
  // console.log(selectedLang)

  // ---------- JSX Code -----------
  return (
    <div className='service-builder-holder service-translate-holder '>

      <h3 className='title mb-4'>{Service_Builder?.flight}</h3>

      <form onSubmit={submit}>

        {/* Flight Service Name */}
        <div className='row align-items-center mb-3'>

          <div className='col-11 col-md-5'>
            <p className='mb-1'>{Service_Builder?.service_name} </p>
            <p className='service-info'>{flightData?.name?.['en']} </p>
          </div>

          <div className='col-1 d-flex pointer-event justify-content-center mt-4'>
            <MagicIcon
              onClick={() =>
                handleAutoTranslate({ targetLanguage: selectedLang, text: flightData?.name?.['en']})
                  .then(result => handleFlightInputsChanges({ key: `name_${selectedLang}`, value: result }))
              }
            />
          </div>

          <div className='col-12 col-md-6 mt-4'>
            <TextField
              type="text"
              hasLabel={false}
              placeholder={Service_Builder?.service_name}
              name={`name_${selectedLang}`}
              id={`name_${selectedLang}`}
              // value={flightData?.[`name_${selectedLang}`]}
              value={formData?.[`name_${selectedLang}`] || ''}
              onChange={(e) => {
                handleFlightInputsChanges({ key: `name_${selectedLang}`, value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: `name_${selectedLang}`, value: e?.target?.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.[`name_${selectedLang}`]?.required ? "danger" : ""}
              errors={errors?.[`name_${selectedLang}`]}
            />
          </div>

        </div>

        {/* Flight Description*/}
        <div className='row align-items-start mb-3'>

          <div className='col-11 col-md-5'>
            <p className='mb-1'> {Service_Builder?.description} </p>
            <p className='service-info'> {flightData?.description?.['en']}</p>
          </div>

          <div className='col-1 d-flex pointer-event justify-content-center mt-4 '>
            <MagicIcon
              onClick={() =>
                handleAutoTranslate({ targetLanguage: selectedLang, text: flightData?.description?.['en']})
                  .then(result => handleFlightInputsChanges({ key: `flight_description_${selectedLang}`, value: result }))
              }
            />
          </div>

          <div className='col-12 col-md-6 mt-3'>
            <TextAreaField
              height={'80px'}
              type="text"
              hasLabel={false}
              placeholder={Service_Builder?.description}
              name={`flight_description_${selectedLang}`}
              id={`flight_description_${selectedLang}`}
              // value={flightData?.[`flight_description_${selectedLang}`]}
              value={formData?.[`flight_description_${selectedLang}`] || ''}
              onChange={(e) => {
                handleFlightInputsChanges({ key: `flight_description_${selectedLang}`, value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: `flight_description_${selectedLang}`, value: e?.target?.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.[`flight_description_${selectedLang}`]?.required ? "danger" : ""}
              errors={errors?.[`flight_description_${selectedLang}`]}
            />
          </div>

        </div>

        {/* Flight Terms And Condition*/}
        {serviceName === 'flight' &&
          <div className='row align-items-start mb-3'>

            <div className='col-11 col-md-5'>
              <p className='mb-1'> {Service_Builder?.termsAndConditions} </p>
              <p className='service-info'> {flightData?.terms?.['en']}</p>
            </div>

            <div className='col-1 d-flex pointer-event justify-content-center mt-4 '>
              <MagicIcon
                onClick={() =>
                  handleAutoTranslate({ targetLanguage: selectedLang, text: flightData?.terms?.['en']})
                    .then(result => handleFlightInputsChanges({ key: `flight_terms_${selectedLang}`, value: result }))
                }
              />
            </div>

            <div className='col-12 col-md-6 mt-3'>
              <TextAreaField
                height={'80px'}
                type="text"
                hasLabel={false}
                placeholder={Service_Builder?.termsAndConditions}
                name={`flight_terms_${selectedLang}`}
                id={`flight_terms_${selectedLang}`}
                // value={flightData?.[`flight_terms_${selectedLang}`]}
                value={formData?.[`flight_terms_${selectedLang}`] || ''}
                onChange={(e) => {
                  handleFlightInputsChanges({ key: `flight_terms_${selectedLang}`, value: e?.target?.value })
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: `flight_terms_${selectedLang}`, value: e?.target?.value },
                      { required: true }
                    ),
                  });
                }}
                color={errors?.[`flight_terms_${selectedLang}`]?.required ? "danger" : ""}
                errors={errors?.[`flight_terms_${selectedLang}`]}
              />
            </div>

          </div>
        }

        {/* Save Button */}

        {serviceName === 'flight' &&
          <div className="col-12 d-flex justify-content-end gap-10 mt-3">
            <button
              type='button'
              onClick={() => history.push('/website/service-builder')}
              className="submit-addNew-btn"
            >
              <span className="btn-overlay"></span>
              <span className="text d-flex align-items-center gap-10 justify-content-center">
                {Service_Builder?.discard}
              </span>
            </button>

            <button type="submit" className="submit-btn ">
              <span className="btn-overlay"></span>
              <span className="text d-flex align-items-center gap-10 justify-content-center">
                {Service_Builder?.save_changes}
              </span>
            </button>
          </div>
        }


      </form >

    </div >
  )
}

export default FlightTranslate