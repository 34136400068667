import { Fragment, useEffect, useState } from 'react'
// react-router-dom
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
// Translation
import Locale from 'translations';
// ReactStarp
import { Collapse } from 'reactstrap';
// React-Notification
import { store } from 'react-notifications-component';
// Components
import SelectField from 'components/Form/SelectField/SelectField';
import TextField from 'components/Form/TextField/TextField';
import TextAreaField from 'components/Form/TextAreaField/TextAreaField';
import UploadFile from 'components/UploadFile';
// Context
import { useSBSState } from 'context/global';
// Shared Components
import MultiTagsTextField from '../shared/MultiTagsTextField';
import HotelPricesTaxes from '../shared/HotelPricesTaxes';
import ServicesSubTabs from '../shared/ServicesSubTabs';
import MiniHotelForm from '../shared/MiniHotelForm';
import MiniFlightForm from '../shared/MiniFlightForm';
import MiniTransferForm from '../shared/MiniTransferForm';
import MiniAttractionForm from '../shared/MiniAttractionForm';
import AttractionPricesTaxes from '../shared/AttractionPricesTaxes';
// Servicies
import { addPackageService, editPackageService, getServicesById } from 'services/serviveBuilder';
// Helper
import validate, { isFormValid } from 'helpers/validate';
import generateUniqueID from 'helpers/generateUniqueID';
// Assets
import { ReactComponent as CloseIcon } from "assets/images/serviceBuilder/x.svg";
import { ReactComponent as AddIcon } from "assets/images/serviceBuilder/orange_add.svg";
import { ReactComponent as TrashIcon } from "assets/images/serviceBuilder/trash.svg";
// Custom Hook
import useSharedData from '../constant/useSharedData';
import useDidMountEffect from 'hooks/useDidMountEffect ';
// ----------------------------------------------------------------------------------------------------


const ServiceBuilderPackagesForm = () => {
  const initialPackageData = {
    serviceId: '',
    name_en: '',
    productType: '',
    stars: '',
    inclusions: null,
    exclusions: null,
    policy_en: '',
    policy_ar: '',
    description_en: '',
    images: [],
    nights_count: 1,
    price: [],
    currency: "SAR",
    taxes: [],
    builder_hotel: [
      {
        // name_en: '',
        country: '',
        city: '',
        hotel_name_en: '',
        // unrequired key must be null
        hotel_stars: '',
        hotel_address: "",
        hotel_amenities: [],
        // refundable: 0,
        policy_en: '',
        cancellation_en: '',
        description_en: '',
        images: [],
        nights_count: 1,
      },
    ],
    builder_flight: [
      {
        name_en: '',
        destination: '',
        from_port: null,
        to_port: null,
        description_en: '',
        images: []
      },
    ],
    builder_transfer: [
      {
        trip_type: 'umrah',
        name_en: '',
        country: null,
        city: null,
        cycle: null,
        vehicleType: "",
        model: "",
        // refundable: 0,
        car_capacity: '',
        withDriver: '',
        policy_en: '',
        description_en: '',
        images: [],
      },
    ],
    builder_attraction: [{
      name_en: "",
      country: '',
      city: '',
      policy_en: "",
      description_en: "",
      tour_type: "",
      stars: "",
      address: "",
      inclusions: [],
      exclusions: [],
      images: []
    },
    ],
    services: [],
    terms_en: ''
  }

  // *** Hooks
  const { Service_Builder, commons, inventory } = Locale
  const { allLookupsBuilder } = useSBSState()
  const history = useHistory()
  const { status, id } = useParams()
  const {
    packageServicesOptions,
    requiredPackageInputs,
    requiredUmrahInputs,
    requiredTourismInputs,
    starsOptions,
    defineServiceName,
    diffTwoArraysOfObjects,
    isPayloadValid,
    prepareBuilderAttraction,
    prepareBuilderFlight,
    prepareBuilderHotels,
    prepareBuilderTransfer,
    prepareShowBuilderAttraction,
    prepareShowBuilderFlight,
    prepareShowBuilderHotel,
    prepareShowBuilderTransfer,
    servicesToShow
  } = useSharedData()

  const packageTypesOptions = allLookupsBuilder?.product_types?.filter(item => item?.name !== 'One service only')


  // *** State
  const [packageData, setPackageData] = useState(initialPackageData);

  const [activeSubTab, setActiveSubTab] = useState(packageData?.services?.[0])
  const [inclusion, setInclusion] = useState('')
  const [exclusion, setExclusion] = useState('')
  const [showPricing, setShowPricing] = useState(false)
  const [errors, setErrors] = useState();
  const [isOpenIndex, setIsOpenIndex] = useState(0);
  const [submitPackageForm, setSubmitPackageForm] = useState(false);
  const [taxesIsSelected, setTaxesIsSelected] = useState("");
  // const [selectedLang, setSelectedLang] = useState("en");
  const packageIncludeHotel = packageData?.services?.map(service => service?.id).some(e => e === 'builder_hotel')

  // *** Function Logic
  function handlePackageInputsChanges({ key, value }, index) {
    let packageDataClone = { ...packageData }
    if (key.startsWith('builder')) {
      packageDataClone[key][index] = value
      setPackageData({
        ...packageDataClone,
        [key]: [...packageDataClone?.[key]]
      })
    } else {
      packageDataClone[`${key}`] = value;
      setPackageData({ ...packageDataClone })
    }
  }

  const handleAddPackageInclusion = () => {
    const packageDataClone = { ...packageData }
    if (inclusion !== '') {
      // setPackageData((prevData) => ({
      //   ...prevData,
      //   inclusions: prevData?.inclusions?.length ? [...prevData?.inclusions, inclusion] : [inclusion]
      // }))
      setPackageData({
        ...packageDataClone,
        inclusions: packageDataClone?.inclusions?.length ? [...packageDataClone?.inclusions, inclusion] : [inclusion]
      })
      setInclusion('')
    }
  }

  const handleRemovePackageInclusion = (index) => {
    // const packageDataClone = { ...packageData }
    // packageDataClone?.inclusions?.splice(index, 1)
    // setPackageData({ ...packageDataClone })
    setPackageData((prevData) => ({
      ...prevData,
      inclusions: prevData.inclusions.filter((_, i) => i !== index)
    }));

  }

  const handleAddPackageExclusion = () => {
    // const packageDataClone = { ...packageData }
    if (exclusion !== '') {
      setPackageData((prevData) => ({
        ...prevData,
        exclusions: prevData?.exclusions?.length ? [...prevData?.exclusions, exclusion] : [exclusion]
      }))
      // setPackageData({
      //   ...packageDataClone,
      //   exclusions: packageDataClone?.exclusions?.length ? [...packageDataClone?.inclusions, exclusion] : [exclusion]
      // })

      setExclusion('')
    }
  }

  const handleRemovePackageExclusion = (index) => {
    // const packageDataClone = { ...packageData }
    // packageDataClone?.exclusions?.splice(index, 1)
    // setPackageData({ ...packageDataClone })
    setPackageData((prevData) => ({
      ...prevData,
      exclusions: prevData.exclusions.filter((_, i) => i !== index)
    }));
  }

  const handleAddNewService = (serviceName) => {
    let newServiceRow = {}
    if (serviceName === 'builder_hotel') {
      newServiceRow = {
        // name_en: '',
        country: '',
        city: '',
        hotel_name_en: '',
        // unrequired key must be null
        hotel_name_ar: null,
        hotel_stars: '',
        hotel_address: "",
        hotel_amenities: [],
        refundable: '',
        policy_en: '',
        cancellation_en: '',
        description_en: '',
        images: [],
        nights_count: 1,
      }
    }
    if (serviceName === 'builder_flight') {
      newServiceRow = {
        name_en: '',
        transporter_id: null,
        destination: '',
        from_port: '',
        to_port: '',
        description_en: '',
        images: []
      }
    }
    if (serviceName === 'builder_transfer') {
      newServiceRow = {
        trip_type: 'umrah',
        name_en: '',
        country: null,
        city: null,
        cycle: null,
        vehicleType: "",
        model: "",
        refundable: '',
        car_capacity: '',
        withDriver: '',
        policy_en: '',
        description_en: '',
        images: [],
      }
    }
    if (serviceName === 'builder_attraction') {
      newServiceRow = {
        name_en: "",
        country: '',
        city: '',
        policy_en: "",
        policy_ar: null,
        description_en: "",
        tour_type: "",
        stars: "",
        address: "",
        inclusions: [],
        exclusions: [],
        images: []
      }
    }
    const packageDataClone = { ...packageData }
    if (Array.isArray(packageDataClone[serviceName])) {
      packageDataClone?.[serviceName]?.push(newServiceRow)
      setPackageData({ ...packageDataClone })
    } else {
      packageDataClone[serviceName] = []
      packageDataClone?.[serviceName]?.push(newServiceRow)
      setPackageData({ ...packageDataClone })
    }
  }

  const handleRemoveService = (serviceName, index) => {
    packageData?.[serviceName]?.splice(index, 1)
    if (packageData?.[serviceName]?.length === 0) {
      packageData[serviceName] = null;
    }
    setPackageData({ ...packageData })
  }

  const handleNextSubTabs = () => {
    const activeTabIndex = packageData?.services?.findIndex(tab => tab?.id === activeSubTab?.id)
    if (activeTabIndex === packageData?.services?.length - 1) {
      setActiveSubTab(packageData?.services?.[0])
    } else {
      setActiveSubTab(packageData?.services[activeTabIndex + 1])
    }
  }

  const toggleCollapse = (index) => {
    setIsOpenIndex((prev) => {
      if (prev === index) return null;
      else return index
    })
  }

  function checkFormErrors() {
    let submitError = {};
    requiredPackageInputs.forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: packageData[key] },
          { required: true }
        )
      }
    })
    setErrors((prev) => ({ ...prev, ...submitError }));
    // console.log(submitError)
    return submitError;
  }

  const checkSelectedServiceValidation = () => {
    const RequiredSelectedServices = packageData?.services?.map(service => service?.id)
    let submitError = {};
    RequiredSelectedServices.forEach(key => {
      if (key === 'builder_tour') {
        submitError = {
          ...submitError,
          [key]: isPayloadValid(packageData['builder_attraction']?.[0])
        }
      } else {
        submitError = {
          ...submitError,
          [key]: isPayloadValid(packageData[key]?.[0])
        }
      }
    })
    // to check validation of the first item builder-transfer inputs
    // if (RequiredSelectedServices.some(e => e === 'builder_transfer')) {
    //   const requiredInputs = packageData?.['builder_transfer']?.[0]?.trip_type === 'umrah' ?
    //     requiredUmrahInputs
    //     :
    //     requiredTourismInputs;

    //   requiredInputs.filter(e => e !== 'terms_en').forEach(key => {
    //     submitError = {
    //       ...submitError,
    //       ...validate(
    //         { name: key, value: packageData['builder_transfer'][0][key] },
    //         { required: true }
    //       )
    //     }
    //   })

    // }

    // console.log(RequiredSelectedServices)
    // to check validation of the list of builder-transfer inputs
    if (RequiredSelectedServices.some(e => e === 'builder_transfer')) {

      packageData?.['builder_transfer']?.map((item, index) => {
        const requiredInputs = packageData?.['builder_transfer']?.[index]?.trip_type === 'umrah' ?
          requiredUmrahInputs
          :
          requiredTourismInputs;
        requiredInputs.filter(e => e !== 'terms_en').forEach(key => {
          submitError = {
            ...submitError,
            ...validate(
              { name: `transfer_${key}`, value: packageData['builder_transfer'][index][key] },
              { required: true }
            )
          }
        })
        return submitError
      }
      )
    }
    // console.log(submitError)
    setErrors((prev) => ({ ...prev, ...submitError }));
    console.log(packageData['builder_attraction']?.[0])
    console.log(packageData['builder_flight']?.[0])
    console.log(isPayloadValid(packageData['builder_attraction'][0]))
    console.log(submitError)
    const isVaild = Object.values(submitError).every(v => (v === true) || (v.required === false))
    // const isVaild = Object.values(submitError).every(v => (v === true && v.required === false))
    console.log(isVaild)
    console.log(Object.values(submitError).map(v => (v === true) || (v.required === false)))
    if (isVaild) {
      return true
    } else {
      return false
    }
  }

  async function submit(e, submitType) {
    e?.preventDefault();
    let formErrors = checkFormErrors();
    let isSelectedServicesValid = checkSelectedServiceValidation();
    console.log(isSelectedServicesValid)
    console.log(formErrors)
    console.log(isFormValid(formErrors))
    setSubmitPackageForm(true)
    if (isFormValid(formErrors) === false || isSelectedServicesValid === false) {
      return
    }
    // console.log('ferwwertwerwerew')
    let requestBody = {
      name: { en: packageData?.name_en },
      product_type_id: packageData?.productType?.id,
      stars: packageData?.stars?.id,
      inclusions: { en: packageData?.inclusions },
      exclusions: { en: packageData?.exclusions },
      // hotel_amenities: packageData?.hotel_amenities,
      is_refundable: packageData?.refundable?.id,
      policy: { en: packageData?.policy_en },
      description: { en: packageData?.description_en },
      terms: { en: packageData?.terms_en },
      images: packageData?.images,
      nights_count: packageData?.nights_count,
      // price: isPayloadValid(packageData?.price?.[0]) ? packageData?.price : null,
      price: showPricing ? (packageIncludeHotel ? packageData?.price || null :
        (packageData?.adult_price || packageData?.child_price ?
          [{ adult_price: packageData?.adult_price || null, child_price: packageData?.child_price || null }]
          :
          null)) : [],
      currency: packageData?.currency,
      // taxes: isPayloadValid(packageData?.taxes?.[0]) ? packageData?.taxes : null,
      // taxes: packageData?.taxes || null,
      taxes: (taxesIsSelected || packageData?.taxes) ? packageData?.taxes.filter(tax => tax.name && tax.type && tax.value) : null
      ,
      services: {
        ...prepareBuilderHotels(packageData?.builder_hotel),
        ...prepareBuilderFlight(packageData?.builder_flight),
        ...prepareBuilderTransfer(packageData?.builder_transfer),
        ...prepareBuilderAttraction(packageData?.builder_attraction),
      }
    }
    // console.log(requestBody)
    if (status === 'add') {
      const response = await addPackageService(requestBody);
      if ((response?.status === 200 || response?.status === 201) && submitType === 'submit-add-new') {
        store.addNotification({
          message: Service_Builder?.ServiceAddedSuccessfully,
          title: Service_Builder?.Success,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true,
          },
        });
        setPackageData(initialPackageData)
      }
      if ((response?.status === 200 || response?.status === 201) && !submitType) {
        history.push('/website/service-builder')
      }
    } else if (status === 'edit') {
      const response = await editPackageService(packageData?.serviceId, requestBody);
      if (response?.status === 200 || response?.status === 201) {
        history.push('/website/service-builder')
      }
    }
  }

  const fetchPackageData = async () => {
    const response = await getServicesById(id);
    if (response?.status === 200) {
      setPackageData({
        serviceId: response?.data?.data?.service?.id,
        name_en: response?.data?.data?.service?.name?.en,
        productType: response?.data?.data?.service?.product_type,
        stars: { name: response?.data?.data?.service?.stars, id: response?.data?.data?.service?.stars },
        inclusions: response?.data?.data?.service?.inclusions?.en,
        exclusions: response?.data?.data?.service?.exclusions?.en,
        policy_en: response?.data?.data?.service?.policy?.en,
        description_en: response?.data?.data?.service?.description?.en,
        terms_en: response?.data?.data?.service?.terms?.en,
        images: response?.data?.data?.service?.images,
        nights_count: response?.data?.data?.service?.nights_count,
        price: response?.data?.data?.service?.price || [],
        currency: response?.data?.data?.service?.currency,
        taxes: response?.data?.data?.service?.taxes?.length ?
          response?.data?.data?.service?.taxes
          :
          [], // { name: '',type: '',value: '',}
        services: servicesToShow(response?.data?.data?.service?.items),
        builder_hotel: prepareShowBuilderHotel(response?.data?.data?.service?.items),
        builder_flight: prepareShowBuilderFlight(response?.data?.data?.service?.items),
        builder_transfer: prepareShowBuilderTransfer(response?.data?.data?.service?.items),
        builder_attraction: prepareShowBuilderAttraction(response?.data?.data?.service?.items),
      })
      setActiveSubTab({
        name: defineServiceName(response?.data?.data?.service?.items[0]?.type),
        id: response?.data?.data?.service?.items[0]?.type
      })
      setShowPricing(response?.data?.data?.service?.price?.length ? true : false)
    }
    return response
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (status === 'edit' && id) {
      fetchPackageData()
    }
  }, [])

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useDidMountEffect(() => {
    if (packageData?.inclusions?.length >= 0) {
      setErrors({
        ...errors,
        ...validate(
          { name: "inclusions", value: packageData?.inclusions?.length },
          { required: true }
        ),
      });
    }
  }, [packageData?.inclusions])

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useDidMountEffect(() => {
    if (packageData?.exclusions?.length >= 0) {
      setErrors({
        ...errors,
        ...validate(
          { name: "exclusions", value: packageData?.exclusions?.length },
          { required: true }
        ),
      });
    }
  }, [packageData?.exclusions])

  useDidMountEffect(() => {
    if (submitPackageForm) {
      setSubmitPackageForm(false)
    }
  }, [packageData])

  console.log(errors)
  console.log(packageData)

  // -------- JSX Code -------
  return (
    <div >
      <h1 className='form-title'>
        {Service_Builder?.select_package_details}
      </h1>

      <form onSubmit={submit} className="mt-4">

        {/* Package Service Form  */}
        <div className='row form-holder package-form mx-auto mb-4'>

          {/* Package Name en */}
          <div className="col-12 col-md-6 custom-field mt-1">
            <TextField
              type="text"
              hasLabel={false}
              label={Service_Builder?.package_name_en}
              placeholder={Service_Builder?.package_name_en}
              name="name_en"
              id="name_en"
              value={packageData?.name_en}
              onChange={(e) => {
                handlePackageInputsChanges({ key: 'name_en', value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "name_en", value: e?.target?.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.name_en?.required ? "danger" : ""}
              errors={errors?.name_en}
            />
          </div>

          {/* Package Type */}
          <div className={`col-12 col-md-6 mt-1  custom-field`}>
            <SelectField
              hasLabel={false}
              label={Service_Builder?.package_type}
              // placeholder={Service_Builder?.package_type}
              labelInner={Service_Builder?.package_type}
              id="productType"
              name="productType"
              value={packageData?.productType?.name}
              options={packageTypesOptions}
              onChange={(e) => {
                handlePackageInputsChanges({ key: 'productType', value: e })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "productType", value: e },
                    { required: true }
                  ),
                });
              }}
              color={errors?.productType?.required ? "danger" : ""}
              errors={errors?.productType}
            />
          </div>

          {/* Package Services */}
          <div className={`col-12 col-md-8 custom-field mt-3 multi-select`}>
            <SelectField
              multi
              hasLabel={false}
              labelInner={Service_Builder?.services}
              id="services"
              name="services"
              label={Service_Builder?.services}
              options={diffTwoArraysOfObjects(packageServicesOptions, packageData?.services, 'id') || packageServicesOptions}
              placeholder={packageData?.services}
              // value={packageData?.services}
              onChange={(values, { action }) => {
                if (action === "remove-value" && !values) {
                  handlePackageInputsChanges({ key: 'services', value: [] })
                  return;
                }
                handlePackageInputsChanges({ key: 'services', value: values })
                setActiveSubTab(values[0])
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "services", value: values.length },
                    { required: true }
                  ),
                });
              }}
              color={errors?.services?.required ? "danger" : ""}
              errors={errors?.services}
            />
          </div>

          {/* Package Star */}
          <div className={`col-12 col-md-4  custom-field mt-3`}>
            <SelectField
              hasLabel={false}
              labelInner={Service_Builder?.star}
              label={Service_Builder?.star}
              // placeholder={Service_Builder?.star}
              id="stars"
              name="stars"
              value={packageData?.stars?.id}
              options={starsOptions}
              onChange={(e) => {
                handlePackageInputsChanges({ key: 'stars', value: e })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "stars", value: e },
                    { required: true }
                  ),
                });
              }}
              color={errors?.stars?.required ? "danger" : ""}
              errors={errors?.stars}
            />
          </div>

          {/* Package Inclusions*/}
          <div className="col-12 col-md-6 mt-3">
            <MultiTagsTextField
              hasLabel={true}
              type="text"
              label={Service_Builder?.inclusions}
              placeholder={Service_Builder?.wifi_free}
              name="inclusions"
              id="inclusions"
              value={inclusion}
              onChange={(e) => setInclusion(e.target.value)}
              onClickAddButton={handleAddPackageInclusion}
              // onEnter={(e) => {
              //   if (e.key === "Enter")
              //     handleAddPackageInclusion();
              // }}
              color={errors?.inclusions?.required ? "danger" : ""}
              errors={errors?.inclusions}
            />
            <div className='d-flex flex-wrap mt-3 '>
              {packageData?.inclusions?.map((item, index) =>
                <div className='tag mx-1' >
                  {item}
                  <span
                    className='pointer-event font-bold'
                    onClick={() => handleRemovePackageInclusion(index)}
                  >
                    <CloseIcon />
                  </span>
                </div>
              )}
            </div>
          </div>

          {/* Package Exclusions*/}
          <div className="col-12 col-md-6 mt-2">
            <MultiTagsTextField
              hasLabel={true}
              type="text"
              label={Service_Builder?.exclusions}
              placeholder={Service_Builder?.wifi_free}
              name="exclusions"
              id="exclusions"
              value={exclusion}
              onChange={(e) => {
                setExclusion(e.target.value)
              }}
              onClickAddButton={handleAddPackageExclusion}
              // onEnter={(e) => {
              //   if (e.key === "Enter")
              //     handleAddPackageExclusion();
              // }}
              color={errors?.exclusions?.required ? "danger" : ""}
              errors={errors?.exclusions}
            />
            <div className='d-flex flex-wrap mt-2 '>
              {packageData?.exclusions?.map((item, index) =>
                <div className='tag mx-1' >
                  {item}
                  <span
                    className='pointer-event font-bold'
                    onClick={() => handleRemovePackageExclusion(index)}
                  >
                    <CloseIcon />
                  </span>
                </div>
              )}
            </div>
          </div>

          {/* Package Description*/}
          <div className="col-12 col-md-6 mt-2 custom-field ">
            <TextAreaField
              hasLabel={false}
              label={Service_Builder?.description}
              height={'126px'}
              placeholder={Service_Builder?.description}
              value={packageData?.description_en}
              onChange={(e) => {
                handlePackageInputsChanges({ key: 'description_en', value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "description_en", value: e?.target?.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.description_en?.required ? "danger" : ""}
              errors={errors?.description_en}
            />
          </div>

          {/* Package Policy*/}
          <div className="col-12 col-md-6  mt-2  custom-field ">
            <TextAreaField
              hasLabel={false}
              height={'126px'}
              label={Service_Builder?.policy}
              placeholder={Service_Builder?.policy}
              value={packageData?.policy_en}
              onChange={(e) => {
                handlePackageInputsChanges({ key: 'policy_en', value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "policy_en", value: e?.target?.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.policy_en?.required ? "danger" : ""}
              errors={errors?.policy_en}
            />
          </div>

          {/* TermsAndConditionsField */}
          <div className="col-12 custom-field mt-3">
            <TextAreaField
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.termsAndConditions}
              placeholder={Service_Builder?.termsAndConditions}
              height={'126px'}
              value={packageData?.terms_en}
              onChange={(e) => {
                handlePackageInputsChanges({ key: 'terms_en', value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "terms_en", value: e?.target?.value },
                    { required: true }
                  ),
                });
              }
              }
              color={errors?.terms_en?.required ? "danger" : ""}
              errors={errors?.terms_en}
            />
          </div>

          {/* Package Iamges*/}
          <div className="col-12 mt-3">
            {/* <p className='font-bold '>{Service_Builder?.add_images}</p> */}
            <div className='images-holder d-flex  align-items-center '>
              <UploadFile
                id={`upload-hotel-image-${generateUniqueID()}`}
                label={Service_Builder?.add_images}
                onChange={(images) => {
                  // handleAddImage(images)
                  handlePackageInputsChanges({ key: 'images', value: images || [] })
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "images", value: images?.length },
                      { required: true }
                    ),
                  });
                }}
                errorMsg={
                  errors?.images?.required
                    ? inventory.viewReservation.Photos + " " + commons.isRequired
                    : false
                }
                value={packageData.images}
              />
            </div>
          </div>

        </div>

        {/* Package Selected Services SubTabs */}
        {packageData?.services?.length >= 1 &&
          <div className='mb-4'>
            <ServicesSubTabs
              tabs={packageData?.services}
              // tabs={packageServicesOptions}
              activeSubTab={activeSubTab}
              setActiveSubTab={setActiveSubTab}
            />
          </div>
        }

        <Fragment>
          { }
          {(activeSubTab?.id === 'builder_hotel' && packageData?.services?.some(ser => ser?.id === 'builder_hotel'))
            &&
            <div className='d-flex justify-content-between align-items-center'>
              <h3 className='form-title my-0'>{Service_Builder?.select_hotel_details}</h3>
              <button
                type='button'
                className='add_btn'
                onClick={() => handleAddNewService('builder_hotel')}
              >
                <AddIcon />{Service_Builder?.add_hotel}
              </button>
            </div>
          }

          {/* Add New Flight Service Button */}
          {(activeSubTab?.id === 'builder_flight' && packageData?.services?.some(ser => ser?.id === 'builder_flight'))
            &&
            <div className='d-flex justify-content-between align-items-center'>
              <h3 className='form-title my-0'>{Service_Builder?.select_flight_details}</h3>
              <button
                type='button'
                className='add_btn'
                onClick={() => handleAddNewService('builder_flight')}
              >
                <AddIcon />{Service_Builder?.add_flight}
              </button>
            </div>
          }

          {/* Add New Transfer Service Button */}
          {(activeSubTab?.id === 'builder_transfer' && packageData?.services?.some(ser => ser?.id === 'builder_transfer'))
            &&
            <div className='d-flex justify-content-between align-items-center'>
              <h3 className='form-title my-0'>{Service_Builder?.select_transfer_details}</h3>
              <button
                type='button'
                className='add_btn'
                onClick={() => handleAddNewService('builder_transfer')}
              >
                <AddIcon />{Service_Builder?.add_transfer}
              </button>
            </div>
          }

          {/* Add New Attraction Service Button */}
          {(activeSubTab?.id === 'builder_tour' && packageData?.services.some(ser => ser?.id === 'builder_tour'))
            &&
            <div className='d-flex justify-content-between align-items-center'>
              <h3 className='form-title my-0'>{Service_Builder?.select_attraction_details}</h3>
              <button
                type='button'
                className='add_btn'
                onClick={() => handleAddNewService('builder_attraction')}
              >
                <AddIcon />{Service_Builder?.add_attraction}
              </button>
            </div>
          }


          {/*  Hotel Service Form */}
          {(activeSubTab?.id === 'builder_hotel' && packageData?.services?.some(ser => ser?.id === 'builder_hotel'))
            &&
            <>
              {packageData?.builder_hotel?.map((hotel, index) =>
                <div className='product-itinerary-container mb-3'>
                  <div className='product-itinerary-title'>
                    <h3 className='collapse-title'>
                      {Service_Builder?.hotel} {index + 1}
                    </h3>
                    <div>
                      <TrashIcon
                        className='mx-2 pointer-event'
                        onClick={() => handleRemoveService('builder_hotel', index)}
                      />
                      <button className='btn shadow-none' type='button' onClick={() => toggleCollapse(index)}>
                        {isOpenIndex === index ?
                          <i className="fas fa-chevron-up" />
                          :
                          <i className="fas fa-chevron-down" />}
                      </button>
                    </div>


                  </div>
                  <Collapse isOpen={isOpenIndex === index}>
                    <MiniHotelForm
                      data={hotel}
                      setData={(recivedData) => handlePackageInputsChanges({ key: 'builder_hotel', value: recivedData }, index)}
                      isSubmit={submitPackageForm}
                      packageType={packageData?.productType?.name}
                    />
                  </Collapse>
                </div>
              )}
            </>
          }

          {/*  Flight Service Form */}
          {(activeSubTab?.id === 'builder_flight' && packageData?.services?.some(ser => ser?.id === 'builder_flight')) &&
            <>
              {packageData?.builder_flight?.map((flight, index) =>
                <div className='product-itinerary-container mb-3'>
                  <div className='product-itinerary-title'>
                    <h3 className='collapse-title'>
                      {Service_Builder?.flight} {index + 1}
                    </h3>
                    <div>
                      <TrashIcon
                        className='mx-2 pointer-event'
                        onClick={() => handleRemoveService('builder_flight', index)}
                      />
                      <button className='btn shadow-none' type='button' onClick={() => toggleCollapse(index)}>
                        {isOpenIndex === index ?
                          <i className="fas fa-chevron-up" />
                          :
                          <i className="fas fa-chevron-down" />}
                      </button>
                    </div>


                  </div>
                  <Collapse isOpen={isOpenIndex === index}>
                    <MiniFlightForm
                      data={flight}
                      setData={(recivedData) => handlePackageInputsChanges({ key: 'builder_flight', value: recivedData }, index)}
                      isSubmit={submitPackageForm}
                      packageType={packageData?.productType?.name}
                    />
                  </Collapse>
                </div>
              )}
            </>
          }

          {/*  Transfer Service Form */}
          {(activeSubTab?.id === 'builder_transfer' && packageData?.services?.some(ser => ser?.id === 'builder_transfer')) &&
            <>
              {packageData?.builder_transfer?.map((transfer, index) =>
                <div className='product-itinerary-container mb-3'>
                  <div className='product-itinerary-title'>
                    <h3 className='collapse-title'>
                      {Service_Builder?.transfer} {index + 1}
                    </h3>
                    <div>
                      <TrashIcon
                        className='mx-2 pointer-event'
                        onClick={() => handleRemoveService('builder_transfer', index)}
                      />
                      <button className='btn shadow-none' type='button' onClick={() => toggleCollapse(index)}>
                        {isOpenIndex === index ?
                          <i className="fas fa-chevron-up" />
                          :
                          <i className="fas fa-chevron-down" />}
                      </button>
                    </div>

                  </div>
                  <Collapse isOpen={isOpenIndex === index}>
                    <MiniTransferForm
                      data={transfer}
                      setData={(recivedData) => handlePackageInputsChanges({ key: 'builder_transfer', value: recivedData }, index)}
                      isSubmit={submitPackageForm}
                      packageType={packageData?.productType?.name}
                    />
                  </Collapse>
                </div>
              )}
            </>
          }

          {/* Attractions Service Form */}
          {(activeSubTab?.id === 'builder_tour' && packageData?.services?.some(ser => ser?.id === 'builder_tour')) &&
            <>
              {packageData?.builder_attraction?.map((attraction, index) =>
                <div className='product-itinerary-container mb-3'>
                  <div className='product-itinerary-title'>
                    <h3 className='collapse-title'>
                      {Service_Builder?.attraction} {index + 1}
                    </h3>
                    <div>
                      <TrashIcon
                        className='mx-2 pointer-event'
                        onClick={() => handleRemoveService('builder_attraction', index)}
                      />
                      <button className='btn shadow-none' type='button' onClick={() => toggleCollapse(index)}>
                        {isOpenIndex === index ?
                          <i className="fas fa-chevron-up" />
                          :
                          <i className="fas fa-chevron-down" />}
                      </button>
                    </div>

                  </div>
                  <Collapse isOpen={isOpenIndex === index}>
                    <MiniAttractionForm
                      data={attraction}
                      setData={(recivedData) => handlePackageInputsChanges({ key: 'builder_attraction', value: recivedData }, index)}
                      isSubmit={submitPackageForm}
                      packageType={packageData?.productType?.name}
                    />
                  </Collapse>
                </div>
              )}
            </>
          }

          {/* Next Button */}
          {packageData?.services?.length >= 2 &&
            <div className='d-flex justify-content-end mb-4'>
              <button type='button' className="next-btn" onClick={handleNextSubTabs}>
                {Service_Builder?.next}
              </button>
            </div>
          }
        </Fragment>

        {/* Show Pricing Checkbox*/}
        <div className="d-flex align-items-center mb-3">
          <input
            type="Checkbox"
            name={"eheck1"}
            id={"exampleCheck1"}
            checked={showPricing}
            onChange={() => setShowPricing(!showPricing)}
          />

          <label
            className="form-check-label pointer font-bold text-caption mx-2 rememberme-label"
            htmlFor={"exampleCheck1"}
          >
            {Service_Builder?.select_pricing}
          </label>
        </div>

        {/* Package Pricing and Taxes*/}
        {showPricing
          &&
          <>
            {
              packageIncludeHotel ?
                <HotelPricesTaxes
                  pricingState={{
                    nights_count: packageData?.nights_count,
                    price: packageData?.price,
                    currency: packageData?.currency,
                    taxes: packageData?.taxes,
                  }}
                  setPricingState={(data) => setPackageData((prevData) => ({ ...prevData, ...data }))}
                  onSelectTaxes={(value) => setTaxesIsSelected(value)}
                />
                :
                <AttractionPricesTaxes
                  // pricingState={{
                  //   price: packageData?.price,
                  //   currency: packageData?.currency,
                  //   taxes: packageData?.taxes,
                  // }}
                  pricingState={{
                    adult_price: packageData?.adult_price || packageData?.price[0]?.adult_price,
                    child_price: packageData?.child_price || packageData?.price[0]?.child_price,
                    currency: packageData?.currency,
                    taxes: packageData?.taxes,
                  }}
                  setPricingState={(data) => setPackageData((prevData) => ({ ...prevData, ...data }))}
                  onSelectTaxes={(value) => setTaxesIsSelected(value)}
                />
            }
          </>
        }

        {/* Save Button */}
        <div className="col-12 d-flex justify-content-end gap-10 mt-3">
          {status === 'add' &&
            <button
              type='button'
              onClick={() => submit(null, 'submit-add-new')}
              className="submit-addNew-btn "
            >
              <span className="btn-overlay"></span>
              <span className="text d-flex align-items-center gap-10 justify-content-center">
                {Service_Builder?.save_add_new}
              </span>
            </button>
          }

          <button type="submit" className="submit-btn ">
            <span className="btn-overlay"></span>
            <span className="text d-flex align-items-center gap-10 justify-content-center">
              {status === 'edit' ? Service_Builder?.save_changes : Service_Builder?.save_close}
            </span>
          </button>
        </div>

      </form>
    </div>
  )
}

export default ServiceBuilderPackagesForm