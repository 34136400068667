import TextField from 'components/Form/TextField/TextField';
import DatePickerField from 'components/Form/DatePickerField/DatePickerField';
import SeachIcon from "assets/images/market-place/servicesIcon/searchIcon.png";
import { ReactComponent as ResetFilterIcon } from "assets/images/market-place/refresh-circle-icon.svg";
import Locale from 'translations';
import { useEffect, useState } from 'react';
import Pagination from "components/Pagination";
import EyeIcon from '../icons/EyeIcon';
export default function RequestSharedTable({ type, requestList, onSearch, onFilterReset, meta, nextPage, handleViewModalOpen }) {

  const { requests, payment } = Locale;
  const [filters, setFilters] = useState({
    search: '',
    date: ''
  });

  const formatISODate = (isoDate) => {
    if (!isoDate) return null;
    const date = new Date(isoDate);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };
  
  useEffect(()=> {
    onSearch({...filters})
  }, [filters])

  return (
    <div className="table__container">
      <div className="table__filter-container">
        <div className="container-fluid">
          <div className="row g-3">
            <div className="col-xl-3 col-lg-5 col-6">
              <TextField
                type="text"
                id="statement-operation-no"
                name="statement-operation-no"
                label={requests.search}
                placeholder={requests.searchRequests}
                isImage={true}
                image={SeachIcon}
                prependImage={true}
                value={filters?.search}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    search: e.target.value,
                  });
                }}
              />
            </div>
            <div className="col-xl-3 col-lg-5 col-6">
              <DatePickerField
                label={requests.requestDate}
                placeholder="DD/MM/YYY"
                date={filters.date}
                onChangeDate={(e) => {
                  setFilters({ ...filters, date: e });
                }}
                isOutsideRange={(day) => {
                  return false;
                }}
              />
            </div>
            <div className="col-xl-2 col-lg-3 col-md-4 d-flex align-items-end">              
              <button
                className='btn reset-btn py-sm my-1'
                onClick={() => {
                  setFilters({
                    search: '',
                    date : null
                  })
                  onFilterReset()
                }}
              >
                <ResetFilterIcon />
                <span className='mx-2'>{payment.messages.clear}</span>
              </button>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <div className="table-responsive table-bordered">
                <table className="table fs-sm mb-0">
                  <thead>
                    <tr>
                      <th className='bg-light font-weight-bold text-capitalize'>{requests.requestId}</th>
                      <th className='bg-light font-weight-bold text-capitalize'>{requests.name}</th>
                      <th className='bg-light font-weight-bold text-capitalize'>{type === "hotels" ?  requests.countryCity : requests.country}</th>
                      <th className='bg-light font-weight-bold text-capitalize'>{requests.requestDate}</th>
                      <th className='bg-light font-weight-bold text-capitalize'>{requests.status}</th>
                      <th className='bg-light font-weight-bold text-capitalize text-center'>{requests.actions}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      requestList?.map((req, index) => (
                        <tr key={index}>
                          <td>
                            {`#${req.id}`}
                          </td>
                          {
                            type === 'hotels' && <td className='font-weight-bolder'>{req.hotel_name ? req.hotel_name : '-'}</td>
                          }
                          {
                            (type === 'flights' || type === 'transporters') && <td className='font-weight-bolder'>{req.name ? req.name : '-'}</td>
                          }
                          <td>
                            <span className='text-capitalize'>{req.country?.name ? req.country.name : '-'}</span>
                            {
                              type === 'hotels' && (
                                <>
                                  <br />
                                  <span className="text-muted text-capitalize fs-xs">{req.city?.name}</span>
                                </>
                              )
                            }
                          </td>
                          <td>{formatISODate(req.created_at) ? formatISODate(req.created_at) : '-'}</td>
                          <td>
                            {
                              (!req.status || req.status === 'new') && (
                                <span className='safa-badge badge-warning'>{requests.requestStatus.pending}</span>
                              )
                            }
                            {
                              (req.status === 'approved') && (
                                <span className='safa-badge badge-success'>{requests.requestStatus.approved}</span>
                              )
                            }
                            {
                              (req.status === 'rejected') && (
                                <span className='safa-badge badge-danger'>{requests.requestStatus.rejected}</span>
                              )
                            }
                          </td>
                          <td className='text-center'>
                            <button className='bg-transparent p-0 border-0 text-muted fs-lg' type='button' onClick={() => {
                              handleViewModalOpen(true, req.id)
                            }}>
                              <EyeIcon width={24} height={24} />
                            </button>
                          </td>
                        </tr>
                      ))
                    }
                    {
                      !requestList?.length && (
                        <tr>
                          <td className='text-center fw-600' colSpan={6}>{requests.noRequestsFound}</td>
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </div>
              <div className="pagination__container">
                {requestList?.length > 0 ?
                  <div className="mt-3">
                    <Pagination info={meta} goTo={nextPage} />
                  </div>
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}