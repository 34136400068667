import { useEffect, useState } from 'react';
import SelectField from 'components/Form/SelectField/SelectField';
import TextField from 'components/Form/TextField/TextField';
import TextAreaField from 'components/Form/TextAreaField/TextAreaField';
import validate from 'helpers/validate';
import NumberField from 'components/Form/NumberField/NumberField';
import Locale from 'translations';
import { fetchCities } from 'services/lookups';
import { useSBSState } from 'context/global'
import MultiTagsTextField from '../../serviceBuilder/shared/MultiTagsTextField';
import { ReactComponent as CloseIcon } from "assets/images/serviceBuilder/x.svg";
import UploadFile from 'components/UploadFile';
import generateUniqueID from 'helpers/generateUniqueID';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { createHotel } from 'services/requests';

export default function RequestSharedHotelForm({type}) {
  const fetchFlag = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";
  const hotelRates = [{
    name: '1 Star',
    id: 1
  }, {
    name: '2 Stars',
    id: 2
  }, {
    name: '3 Stars',
    id: 3
  }, {
    name: '4 Stars',
    id: 4
  }, {
    name: '5 Stars',
    id: 5
  }];
  const { requests } = Locale;
  const [hotel, setHotel] = useState({
    hotel_name: '',
    _rating: '',
    rating: '',
    phone: '',
    phone_code: '966',
    flag: '',
    _country_id: '',
    country_id: '',
    _city_id: '',
    city_id: '',
    address: '',
    latitude: '',
    longitude: '',
    amenities: [],
    description: '',
    main_image: '',
    images: []
  });
  const [errors, setErrors] = useState({
    hotel_name: { required: true, touched: false },
    rating: { touched: false },
    phone_code: { touched: false },
    phone : { touched: false },
    country_id: { required: true, touched: false },
    city_id: { required : true, touched: false },
    address: { touched: false },
    latitude: { touched: false },
    longitude: { touched: false },
    amenities : { touched: false },
    description: { touched: false },
    images : { length : true, touched: false }
  });
  const { allCountries } = useSBSState();
  const [cities, setCityList] = useState([]);
  const [hotelAmenity, setHotelAmenity] = useState('');
  const history = useHistory();

  const handleFormSubmit = (event) => {
  }

  const fetchCity = async (id) => {
    if (id) {
      const cities = await fetchCities(id);
      setCityList(cities);
    }
    else {
      setCityList([]);
    }
  };

  const handleAddHotelAmenity = () => {
    const hotel_info = { ...hotel }
    if (hotelAmenity !== '') {
      setHotel({
        ...hotel,
        amenities: [...hotel_info?.amenities, hotelAmenity]
      })
      setErrors({
        ...errors,
        ...validate(
          { name: "amenities", value: [...hotel_info?.amenities, hotelAmenity] },
          { required: true }
        ),
      });
      setHotelAmenity('')
    }
  }

  const handleRemoveHotelAmenity = (index) => {
    const hotel_info = { ...hotel }
    hotel_info?.amenities?.splice(index, 1)
    setHotel({ ...hotel_info })
  }

  const formValid = ()=> {
    const errorKeys = Object.keys(errors);
    if(errorKeys.length){
      return errorKeys.every(k => !errors[k]?.required);
    }
    return true;
  }

  const markAllAsTouched = ()=>{
    setErrors((prevErrors) => {
      const updatedErrors = {};
      for (const key in prevErrors) {
        if (prevErrors.hasOwnProperty(key)) {
          updatedErrors[key] = {
            ...prevErrors[key],
            touched: true,
          };
        }
      }
      return updatedErrors;
    });
  }

  const save = async (event)=>{
    event.preventDefault();
    if(!formValid() || !hotel?.images?.length){
      markAllAsTouched();
      return;
    }
    const res = await createHotel({...hotel});
    if(res?.status === 200 || res?.status === 201){
      history.push(`/requests/${type}`)
    }
  }

  useEffect(() => {
    const sa = allCountries?.filter(c => c.phone_code === '+966')?.[0];
    if (sa) {
      setHotel({
        ...hotel,
        flag: sa.flag
      });
    }
  }, [allCountries])

  return (
    <form onSubmit={handleFormSubmit}>
      <div className="container-fluid">
        <div className="row g-4">
          <div className="col-xl-4 col-lg-4 col-md-6 mb-2">
            <TextField
              withStar={true}
              type="text"
              hasLabel={true}
              label={requests?.hotelName}
              placeholder={requests?.hotelName}
              name="hotel_name"
              id="hotel_name"
              value={hotel?.hotel_name}
              onBlur = {()=>{ setErrors({
                ...errors, 
                hotel_name: {
                  ...errors.hotel_name,
                  touched: true
                }
              })}}
              onChange={(e) => {
                setHotel({
                  ...hotel,
                  hotel_name: e?.target?.value
                });
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "hotel_name", value: e?.target?.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.hotel_name?.required && errors?.hotel_name?.touched ? "danger" : ""}
              errors={errors?.hotel_name?.touched ? errors?.hotel_name : null}
            />
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 mb-2">
            <SelectField
              withStar={false}
              hasLabel={true}
              label={requests?.hotelRate}
              placeholder={`-- ${requests.select} ${requests?.hotelRate} --`}
              id="rating"
              name="rating"
              value={hotel._rating}
              options={hotelRates}
              onChange={(e) => {
                setHotel({
                  ...hotel,
                  _rating: e.label,
                  rating: e.value
                })
              }}
            />
          </div>
          <div className="col-xl-5 mb-2">
            <div className="row px-2">
              <div className="col-12">
                <label className="control-field__label col-12">{requests.phone}</label>
              </div>
              <div className="col-xl-4 col-5">
                <SelectField
                  hasLabel={false}
                  label = {requests.countryCode}
                  id="phone_code"
                  name="phone_code"
                  value={
                    hotel?.flag ?
                      <img
                        src={`${fetchFlag}/${hotel?.flag}`}
                        width="35px"
                        alt={'Selected Country Code Flag'}
                      />
                      :
                      (hotel.phone_code)
                  }
                  onChange={(e) => {
                    setHotel({
                      ...hotel,
                      phone_code: e.value ? String(e.value) : '',
                      flag: e.flag ? e.flag : ''
                    })
                  }}
                  options={allCountries}
                />
              </div>
              <div className="col-xl-8 col-7">
                <NumberField
                  type={"phone"}
                  label={requests.phone}
                  hasLabel={false}
                  id="phone"
                  name="phone"
                  value={hotel.phone}
                  removeArrow={true}
                  placeholder={"000 0000 000"}
                  min={3}
                  onChange={(e) => {
                    setHotel({
                      ...hotel,
                      phone: e.target.value
                    })
                  }
                }
                  extraTextPosition="prepend"
                  extraText={
                    '+' + hotel?.phone_code
                  }
                />
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 mb-2">
            <SelectField
              id="country_id"
              name="country_id"
              withStar={true}
              hasLabel={true}
              label={requests.country}
              options={allCountries}
              value={hotel?._country_id}
              onBlur = {()=>{ setErrors({
                ...errors, 
                country_id: {
                  ...errors.country_id,
                  touched: true
                }
              })}}
              onChange={(e) => {
                fetchCity(e.id);
                setHotel({
                  ...hotel,
                  _country_id: e.label,
                  country_id: e.value,
                  city_id: '',
                  _city_id : ''
                })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "country_id", value: e?.value },
                    { required: true }
                  ),
                  city_id: {
                    required: true,
                    touched: true
                  }
                });
              }}
              color={errors.country_id?.required && errors.country_id?.touched ? "danger" : ""}
              errors={errors.country_id?.touched ? errors.country_id : null}
            />
          </div>
          <div className="col-xl-3 col-sm-6 mb-2">
            <SelectField
              id="city_id"
              name="city_id"
              withStar={true}
              hasLabel={true}
              label={requests.city}
              options={cities}
              value={hotel?._city_id}
              onBlur = {()=>{ setErrors({
                ...errors, 
                city_id: {
                  ...errors.city_id,
                  touched: true
                }
              })}}
              onChange={(e) => {
                setHotel({
                  ...hotel,
                  _city_id: e.label,
                  city_id: e.value
                })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "city_id", value: e?.value },
                    { required: true }
                  ),
                });
              }}
              color={errors.city_id?.required && errors.city_id?.touched ? "danger" : ""}
              errors={errors.city_id?.touched ? errors.city_id : null}
            />
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 mb-2">
            <TextField
              withStar={false}
              type="text"
              hasLabel={true}
              label={requests?.latitude}
              placeholder={requests?.latitude}
              name="latitude"
              id="latitude"
              value={hotel?.latitude}
              onChange={(e) => {
                setHotel({
                  ...hotel,
                  latitude: e?.target?.value
                });
              }}
            />
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 mb-2">
            <TextField
              withStar={false}
              type="text"
              hasLabel={true}
              label={requests?.longitude}
              placeholder={requests?.longitude}
              name="longitude"
              id="longitude"
              value={hotel?.longitude}
              onChange={(e) => {
                setHotel({
                  ...hotel,
                  longitude: e?.target?.value
                });
              }}
            />
          </div>
          <div className="col-12 mb-2">
            <TextField
              withStar={false}
              type="text"
              hasLabel={true}
              label={requests?.address}
              placeholder={requests?.address}
              name="address"
              id="address"
              value={hotel?.address}
              onChange={(e) => {
                setHotel({
                  ...hotel,
                  address: e?.target?.value
                });
              }}
            />
          </div>
          <div className="col-12 mb-3">
            <MultiTagsTextField
              hasLabel={true}
              withStar={false}
              type="text"
              label={requests?.hotelAmenities}
              placeholder={requests?.hotelAmenities}
              name="amenities"
              id="amenities"
              value={hotelAmenity}
              onChange={(e) => {
                setHotelAmenity(e?.target?.value)
              }}
              onClickAddButton={handleAddHotelAmenity}
              onEnter={(e) => {
                if (e.key === "Enter")
                  handleAddHotelAmenity();
              }}
            />
            <div className='d-flex flex-wrap mt-2 '>
              {hotel?.amenities?.map((amenity, index) =>
                <div className='tag mx-1 my-1' key={index}>
                  {amenity}
                  <span
                    className='pointer-event font-bold'
                    onClick={() => handleRemoveHotelAmenity(index)}
                  >
                    <CloseIcon />
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="col-12 mb-2">
            <label className='fw-500 mb-1'>
              {requests?.hotelDesc}
            </label>
            <TextAreaField
              hasLabel={false}
              label={requests?.hotelDesc}
              height={'126px'}
              placeholder={requests?.hotelDesc}
              value={hotel?.description}
              onChange={(e) => {
                setHotel({
                  ...hotel,
                  description: e.target.value
                })
              }}
            />
          </div>
          <div className="col-12 mb-4">
            <label className='fw-500 mb-1'>
              {requests?.addImages}
              <span className="text-danger">*</span>
            </label>
            <UploadFile
              id={`upload-hotel-image-${generateUniqueID()}`}
              onChange={(images) => {
                setHotel({
                  ...hotel,
                  main_image: images?.length ? images[0] : '',
                  images: images?.length ? images : []
                })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "images", value: images || [] },
                    { length: true }
                  ),
                });
              }}
              errorMsg={
                !hotel?.images?.length && errors?.images?.touched
                  ? requests?.hotelImagesRequired
                  : null
              }
              value={hotel.images}
            />

          </div>
          <div className="col-12 d-flex justify-content-end">
            <button type='submit' className='btn-Product-Filter bg-nxt' disabled={false} onClick={save}>{requests.saveRequest}</button>
          </div>
        </div>
      </div>
    </form>
  )

}