import Locale from 'translations';
import { Link, useLocation, useParams } from "react-router-dom";
import RequestSharedTable from './shared/RequestTable';
import RequestViewModal from './shared/RequestView';
import { Fragment, useEffect, useState } from 'react';
import { getFlightRequest, getFlightRequests, getHotelRequest, getHotelRequests, getTransporterRequest, getTransporterRequests } from 'services/requests';
import Pagination from 'components/Pagination';
import moment from 'moment';
import NoSegmentMatch from "assets/images/rquests/segment-no-match.svg"

export default function RequestList() {
  const { requests } = Locale;
  const location = useLocation();
  const { type } = useParams();
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [currentViewId, setCurrentViewId] = useState(null);
  const [currentView, setCurrentView] = useState(null);
  const [requestList, setRequests] = useState([]);
  const [meta, setMeta] = useState({});
  const [filters, setFilters] = useState({
    search: '',
    date: '',
    page_size: 10,
    page: 1
  });
  const [debounceFilters, setDebounceFilters] = useState({
    search: '',
    date: '',
    page_size: 10,
    page: 1
  });

  const isActive = (path) => {
    return location.pathname?.includes(path);
  }
  const activePath = () => {
    if (isActive(path.hotels)) return path.hotels;
    else if (isActive(path.flights)) return path.flights;
    else if (isActive(path.transporters)) return path.transporters;
    return isActive(path.hotels);
  }

  const handleViewModalOpen = async (open, id) => {
    if (open) setCurrentViewId(id);
    else setCurrentViewId(null);
    if (id) {
      let res;
      if (type === 'hotels') {
        res = await getHotelRequest(id);
      }
      else if (type === 'flights') {
        res = await getFlightRequest(id);
      }
      else if (type === 'transporters') {
        res = await getTransporterRequest(id);
      }
      if (res?.status === 200 || res?.status === 201) {
        if (res?.data?.data) {
          setCurrentView(res.data.data);
          setViewModalOpen(open);
        }
      }
    }
  }

  const path = {
    hotels: '/requests/hotels',
    flights: '/requests/flights',
    transporters: '/requests/transporters'
  }

  const getRequestFilters = () => {
    const reqFilters = { ...debounceFilters };
    if (!reqFilters) return {};
    if (!reqFilters.date) {
      delete reqFilters.date;
    }
    if (!reqFilters.search) {
      delete reqFilters.search;
    }
    return { ...reqFilters };
  }

  const goTo = (page) => {
    setFilters((prevFilter) => ({ ...prevFilter, page }));
  }

  const onSearch = (e) => {
    if (e.date) {
      const currentDate = moment(e.date);
      const formattedDate = currentDate.format('YYYY-MM-DD');
      setFilters({
        ...filters,
        page: 1,
        search: e.search,
        date: formattedDate
      })
    }
    else {
      setFilters({
        ...filters,
        page: 1,
        search: e.search,
        date: null
      })
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebounceFilters({ ...filters }); // Update the debounced term after the delay
    }, 500); // 500ms debounce time
    return () => {
      clearTimeout(timer); // Clear the timeout on cleanup or searchTerm change
    };
  }, [filters])

  useEffect(() => {
    getList(type);
  }, [type, debounceFilters]);

  async function getList(type) {
    let res;
    if (type === 'hotels') {
      res = await getHotelRequests({
        ...getRequestFilters()
      });
    }
    else if (type === 'flights') {
      res = await getFlightRequests({
        ...getRequestFilters()
      });
    }
    else if (type === 'transporters') {
      res = await getTransporterRequests({
        ...getRequestFilters()
      });
    }
    if (res?.status === 200) {
      setRequests(res?.data?.data?.length ? res.data.data : []);
      setMeta(res?.data?.meta ? res.data.meta : {});
    }
    else {
      setRequests([]);
      setMeta({});
    }
  }

  return (
    <div className="request__page-container">
      {
        (type === 'hotels' || type === 'flights' || type === 'transporters') ? (
          <Fragment>
            <div className="card border-0 mb-3">
              <div className="card-body">
                <div className="navigation__links">
                  <Link to={path.hotels} class={`navigation__link ${isActive(path.hotels) ? 'active' : ''}`}>{requests.hotels}</Link>
                  <Link to={path.flights} class={`navigation__link ${isActive(path.flights) ? 'active' : ''}`}>{requests.flights}</Link>
                  <Link to={path.transporters} class={`navigation__link ${isActive(path.transporters) ? 'active' : ''}`}>{requests.transporters}</Link>
                </div>
              </div>
            </div>
            <div className="card border-0">
              <div className="card-body p-4">
                <div className="card-title d-flex justify-content-between gap-2 flex-wrap align-items-center fs-lg mb-0">
                  {
                    isActive(path.hotels) && (
                      <h1 className="font-weight-bold">{requests.hotelRequests}</h1>
                    )
                  }
                  {
                    isActive(path.flights) && (
                      <h2 className="font-weight-bold mb-0">{requests.flightRequests}</h2>
                    )
                  }
                  {
                    isActive(path.transporters) && (
                      <h2 className="font-weight-bold mb-0">{requests.transporterRequests}</h2>
                    )
                  }
                  <Link to={activePath() + `/new-request`} className='btn-Product-Filter bg-nxt'>{requests.newRequest}</Link>
                </div>
                <hr className="requests__hr mb-4" />
                <RequestSharedTable handleViewModalOpen={handleViewModalOpen} requestList={requestList} type={type} onSearch={onSearch} onFilterReset={() => {
                  setFilters({
                    ...filters,
                    page: 1,
                    search: '',
                    date: ''
                  })
                }} />
                <div className="my-2">
                  <Pagination info={meta} goTo={goTo} />
                </div>
              </div>
            </div>
            <RequestViewModal type={type} open={viewModalOpen} closeModal={() => setViewModalOpen(false)} viewId={currentViewId} currentView={currentView} />
          </Fragment>
        ) : (
          <div className='card border-0'>
            <div className="card-body d-flex flex-column justify-content-center align-items-center p-lg-5 p-4">
              <div className="icon mb-3">
                <img width={250} height={'auto'} src={NoSegmentMatch} alt="No Segment Matches" />
              </div>
              <div className="text">
                <p className="fw-600 my-0 mx-auto text-center" style={{
                  maxWidth: '450px'
                }}>{requests?.segmentMismatch}</p>
              </div>
            </div>
          </div>
          
        )
      }
    </div>
  )
}