import React, { useEffect, useState } from "react";
import BreadCrump from "./BreadCrump";
import SelectField from "components/Form/SelectField/SelectField";
import { createOrderViolation, fetchOrdersViolationById, getAgentsLookup, getStatementsList, updateOrderViolation } from "services/operationStatement";
import TextField from "components/Form/TextField/TextField";
import { useSBSState } from "context/global";
import moment from "moment";
import TextAreaField from "components/Form/TextAreaField/TextAreaField";
import { useParams } from "react-router-dom";
import Locale from "translations";
import { useHistory } from 'react-router-dom';
import DateTimePickerField from 'components/Form/DateTimePickerField/DateTimePickerField';
import UploadInput from 'components/UploadInput';
import { GalleryremoveIcon } from '../shared/Icons';
import AddPassportBtn from './AddPassportBtn';
import { handleResponesNotification } from '../helper/accountingHelpers';
import validate, { isFormValid } from 'helpers/validate';
import NumberField from 'components/Form/NumberField/NumberField';
import ShowSubscription from 'helpers/ShowSubscription';
import ShowForPermission from 'helpers/showForPermission';

// ** lookups
const SAR_OBJ = {
  id: 95,
  name: "Saudi Riyal",
  currency_code: "SAR",
}

const keyNotRequired = ["statement",]

export default function AddEditViolationsOrder() {

  // ** hooks
  const { allCurrencies, locale } = useSBSState();
  const { reference, id } = useParams();
  const { operationStatement, commons, ordersViolations } = Locale;
  const history = useHistory();

  const VIOLATION_TYPES = [
    { id: 0, name: ordersViolations.flight, type: "flight" },
    { id: 1, name: ordersViolations.transportation, type: "transportation" },
    { id: 2, name: ordersViolations.hotel, type: "hotel" },
  ]

  // ** states
  const [agentsList, setAgentsList] = useState([]);
  const [statementsList, setStatementsList] = useState([]);
  const [data, setData] = useState({
    agent: null,
    statement: null,
    dateTime: "",
    violationType: null,
    violationCost: "",
    violationCurrency: SAR_OBJ,
    violationFile: null,
    invoiceFile: null,
    pax: 1,
    passengers: [""],
    description: null,
  });

  const [errors, setErrors] = useState({});


  // ** vars
  const isEditView = reference === "edit" || reference === "view";
  const isViewOnly = reference === "view";
  const isAddView = reference === "add";


  // ** functions
  const handleOnChange = (key, value) => {
    setData({ ...data, [key]: value });
    setErrors({
      ...errors,
      ...validate(
        { name: key, value },
        { required: true, minNumber: key === "pax" && data.passengers.length }
      ),
    })
  }

  const handleAgentPassportChange = (index) => {
    return (e) => {
      const passengers = data.passengers;
      passengers[index] = e.target.value;
      setData({ ...data, passengers });
      setErrors({
        ...errors,
        ...validate(
          { name: `passenger-${index}`, value: e.target.value },
          { required: true }
        ),
      })
    };
  };

  const handleAddPassport = () => {
    setData({ ...data, passengers: [...data.passengers, ""] });
  }

  const handleRemovePassport = (index) => {
    const updatedData = [...data.passengers].filter((_, i) => i !== index);
    setData((prevData) => {
      return { ...data, passengers: updatedData };
    });
    setErrors({
      ...errors,
      ...validate(
        { name: "pax", value: data.pax },
        { minNumber: updatedData.length }
      ),
    })
  };

  const checkFormErrors = () => {
    let submitErrors = {}
    Object.keys(data).forEach(key => {
      if (!keyNotRequired.includes(key)) {
        submitErrors = {
          ...submitErrors,
          ...validate(
            { name: key, value: data[key] },
            { required: true, minNumber: key === "pax" && data.passengers.length }
          ),
        }
      }
    });

    data.passengers.forEach((passenger, index) => {
      submitErrors = {
        ...submitErrors,
        ...validate(
          { name: `passenger-${index}`, value: passenger },
          { required: true }
        ),
      }
    })

    setErrors(submitErrors);
    return submitErrors;
  }

  const handleSave = async () => {
    let fromErros = checkFormErrors();

    if (isFormValid(fromErros)) {
      const dataToUpload = {
        agent_id: data.agent.id,
        statement_id: data.statement?.id,
        date: data.dateTime ? moment(data.dateTime).format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ") : null,
        violation_type: data.violationType?.type,
        cost: data.violationCost,
        currency: data.violationCurrency.currency_code,
        file: data.violationFile,
        invoice: data.invoiceFile,
        pax: data.pax,
        passengers: data.passengers,
        description: data.description,
      }

      let res = isEditView ? await updateOrderViolation(id, dataToUpload) : await createOrderViolation(dataToUpload);
      let message = isEditView ? "Order Violation updated successfully" : "Order Violation added successfully";

      if (res?.status === 200 || res?.status === 201) {
        handleResponesNotification({ alertType: "success", message, title: "" });

        history.goBack();
      }
    }
  };

  useEffect(() => {
    const fetchAgentsList = async () => {
      const res = await getAgentsLookup({});
      setAgentsList(mapAgentsListToOptions(res.data?.data));
    };

    if (!isViewOnly) {
      fetchAgentsList()
    }
  }, [isViewOnly]);


  // get order violation by id 
  useEffect(() => {
    const fetchData = async () => {
      if (isEditView) {
        const resData = await fetchOrdersViolationById(id);
        const mappedData = {
          agent: resData.data.data?.agent,
          statement: { ...resData.data.data?.statement, name: resData.data.data.statement?.reference_number },
          dateTime: resData.data.data.date ? moment(resData.data.data.date).subtract(3, "hours") : null,
          violationType:
            VIOLATION_TYPES.find((type) => type.type === resData.data.data.violation_type),
          violationCost: resData.data.data.cost,
          violationCurrency: resData.data.data.currency_details,
          violationFile: resData.data.data.file,
          invoiceFile: resData.data.data.invoice,
          pax: resData.data.data.pax,
          passengers: resData.data.data.passengers,
          description: resData.data.data.description,
        }
        setData(mappedData);
      };
    }

    fetchData();
  }, [id, isEditView]);

  // fetch statements list based on selected agent
  useEffect(() => {
    if (data.agent && !isViewOnly) {
      const fetchOperationStatementsList = async () => {
        const res = await getStatementsList({ agent_ids: [data.agent.id] });
        setStatementsList(mapStatementsListToOptions(res.data.data));
      };

      fetchOperationStatementsList();
    }
  }, [data.agent, isViewOnly]);

  const breadCrumbLinks = generateBreadCrumbLinks(isEditView, isAddView, id, operationStatement);


  return (
    <ShowSubscription module="order_violation">
      <div className="orders-violations-container px-new-26">
        <BreadCrump links={breadCrumbLinks} />

        <div>
          <div className="orders-violations-card">
            <div className="row mx-0">
              {/* agent name */}
              <div className="col-md-3 col-12 mb-3">
                <SelectField
                  label={operationStatement.agentName}
                  placeholder={operationStatement.selectAgent}
                  id="agentName"
                  name="agentName"
                  options={agentsList}
                  value={data.agent?.name}
                  onChange={(selectedAgent) => handleOnChange("agent", selectedAgent)}
                  color={errors?.agent?.required ? "danger" : ""}
                  errors={errors?.agent}
                  disabled={isViewOnly}
                />
              </div>
              {/* agent country */}
              <div className="col-md-3 col-12 mb-3">
                <TextField
                  id="agentCountry"
                  name="agentCountry"
                  value={data.agent?.country?.name?.[locale] || data.agent?.country?.name || data.agent?.country}
                  label={operationStatement.agentCountry}
                  placeholder={operationStatement.agentCountry}
                  disabled
                />
              </div>
              {/* operation Statement */}
              <div className="col-md-3 col-12 mb-3">
                <SelectField
                  label={operationStatement.operationStatement}
                  placeholder={operationStatement.selectOperation}
                  id="statementOperation"
                  name="statementOperation"
                  options={statementsList}
                  value={data.statement?.name}
                  onChange={(selectedStatement) => handleOnChange("statement", selectedStatement)}
                  color={errors?.statement?.required ? "danger" : ""}
                  errors={errors?.statement}
                  disabled={isViewOnly}
                />
              </div>
              {/* date and Time */}
              <div className="col-md-3 col-12 mb-3">
                <DateTimePickerField
                  label={operationStatement.dateTime}
                  placeholder={operationStatement.selectDate}
                  date={data.dateTime ? new Date(data.dateTime) : data.dateTime}
                  onChangeDate={(date) => handleOnChange("dateTime", date)}
                  color={errors?.dateTime?.required ? "danger" : ""}
                  errors={errors?.dateTime}
                  disabled={isViewOnly}
                />
              </div>
              {/* Violation Type */}
              <div className="col-md-3 col-12 mb-3">
                <SelectField
                  label={operationStatement.violationType}
                  placeholder={operationStatement.selectType}
                  id="violationType"
                  name="violationType"
                  value={data.violationType?.name || null}
                  options={mapViolationTypesToOptions(VIOLATION_TYPES)}
                  onChange={(selectedType) => {
                    handleOnChange("violationType", selectedType)
                  }}
                  color={errors?.violationType?.required ? "danger" : ""}
                  errors={errors?.violationType}
                  disabled={isViewOnly}
                />
              </div>
              {/* Violation Cost */}
              <div className="col-md-3 col-12 mb-3 violation-cost-wrapper">
                <div className="violation-cost-input">
                  <TextField
                    label={operationStatement.violationCost}
                    placeholder={operationStatement.enterAmount}
                    name="violationCost"
                    id="violationCost"
                    value={data.violationCost}
                    type="number"
                    onChange={(e) => handleOnChange("violationCost", Number(e.target.value).toString())}
                    color={errors?.violationCost?.required ? "danger" : ""}
                    errors={errors?.violationCost}
                    disabled={isViewOnly}
                  />
                </div>
                <div className="violation-cost-select">
                  <SelectField
                    disabled={true}
                    id="violationCostCurrency"
                    name="violationCostCurrency"
                    value={data.violationCurrency?.currency_code || data.violationCurrency?.name}
                    options={allCurrencies}
                    onChange={(selectedCurrency) => handleOnChange("violationCurrency", selectedCurrency)}
                    // disabled={isViewOnly}
                  />
                </div>
              </div>
              {/* Attach Violation File */}
              <div className="col-md-3 col-12 mb-3">
                <UploadInput
                  label={`${operationStatement.attachViolationFile} (PDF, Text, Doc)`}
                  onUpload={(_, fileId) => handleOnChange("violationFile", fileId)}
                  accept=".pdf, .txt, .doc, .docx, image/png, image/jpeg"
                  bucket='ordersviolations'
                  errorMsg={
                    errors?.violationFile?.required
                      ? operationStatement.attachViolationFile + " " + commons.isRequired
                      : false
                  }
                  disabled={isViewOnly}
                />
              </div>
              {/* Attach Violation Invoice */}
              <div className="col-md-3 col-12 mb-3">
                <UploadInput
                  label={`${operationStatement.attachInvoice} (PDF, Text, Doc)`}
                  onUpload={(_, fileId) => handleOnChange("invoiceFile", fileId)}
                  accept=".pdf, .txt, .doc, .docx, image/png, image/jpeg"
                  bucket='ordersviolations'
                  errorMsg={
                    errors?.invoiceFile?.required
                      ? operationStatement.attachInvoice + " " + commons.isRequired
                      : false
                  }
                  disabled={isViewOnly}
                />
              </div>
            </div>
          </div>

          <div className="row mx-0 mt-3">
            <div className="col-12 col-lg-6 px-0 pr-lg-2 mb-2">
              <div className="orders-violations-card">
                <div className="row mx-0">
                  <div className="col-6">
                    <NumberField
                      label={operationStatement.travelerViolationsPAX}
                      placeholder={operationStatement.enterPAX}
                      id="pax"
                      name="pax"
                      removeArrow={true}
                      min={data.passengers.length}
                      value={data.pax}
                      onChange={(e) => {
                        handleOnChange("pax", Number(+e.target.value).toString())
                      }}
                      color={errors?.pax?.required ? "danger" : ""}
                      errors={errors?.pax}
                      disabled={isViewOnly}
                    />
                  </div>
                  <div className="col-6">
                    {data.passengers.map((_, index) => {
                      return (
                        <div className="d-flex" key={`passenger-${index}`}>
                          <div className="w-100">
                            <TextField
                              hasLabel={index === 0}
                              label={ordersViolations.passportNumber}
                              name={`passenger`}
                              id={`passenger`}
                              value={data.passengers[index]}
                              placeholder={ordersViolations.enterPassportNumber}
                              onChange={handleAgentPassportChange(index)}
                              color={errors?.[`passenger-${index}`]?.required ? "danger" : ""}
                              errors={errors?.[`passenger-${index}`]}
                              disabled={isViewOnly}
                            />
                          </div>
                          {index > 0 ?
                            <div className="remove-icon mt-2">
                              <button className="btn px-0" onClick={() => handleRemovePassport(index)}>
                                <GalleryremoveIcon color="#EA5455" />
                              </button>
                            </div>
                            :
                            null
                          }
                        </div>
                      )
                    })}
                    {data.passengers.length < parseInt(data.pax, 10)
                      ? <AddPassportBtn onClick={handleAddPassport} />
                      : null
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-6 px-0">
              <div className="orders-violations-card">
                <TextAreaField
                  label={ordersViolations.violationsDescription}
                  placeholder={ordersViolations.enterDescription}
                  name="description"
                  id="description"
                  value={data.description}
                  height={160}
                  onChange={
                    e => handleOnChange('description', e.target.value)
                  }
                  color={errors?.description?.required ? "danger" : ""}
                  errors={errors?.description}
                  disabled={isViewOnly}
                />
              </div>
            </div>
          </div>
        </div>

        <ShowForPermission permission="Manage-Order-Violation">
          <div className="save-btn-wrapper mt-3 py-3">
            <button className="btn" onClick={handleSave} disabled={isViewOnly}>{Locale.guideRequests.save}</button>
          </div>
        </ShowForPermission>
      </div >
    </ShowSubscription>
  );
}

const mapAgentsListToOptions = (agentsList) => {
  return agentsList.map((agent) => ({
    id: agent.id,
    name: agent.name,
    country: agent.country.name.en,
  }));
};

const mapStatementsListToOptions = (statementsList) => {
  return statementsList.map((statement) => ({
    id: statement.id,
    name: statement.company_reference_number,
  }));
};

const mapViolationTypesToOptions = (data) =>
  data.map(({ name, type }, index) => ({ name, type, id: index }));

function generateBreadCrumbLinks(isEditView, isAddView, id, operationStatement) {
  const baseBreadCrumbLinks = [
    { name: operationStatement.umrahCompanyOperations, url: "/operations/operation-statement" },
    { name: Locale.ordersViolations.ordersViolations, url: "/operations/orders-violations" },
  ];

  let editOrAddLink;

  if (isEditView) {
    editOrAddLink = {
      name: operationStatement.edit,
      url: `/operations/orders-violations/edit/${id}`
    }
  }
  else if (isAddView) {
    editOrAddLink = {
      name: operationStatement.add,
      url: `/operations/orders-violations/add`
    }
  }

  const breadCrumbLinks = [
    ...baseBreadCrumbLinks,
    ...(editOrAddLink ? [editOrAddLink] : [])
  ];

  return breadCrumbLinks;
}