import { ReactComponent as EditIcon } from "assets/images/webBuilder/edit-hero.svg";
import { ReactComponent as GalleryEditIcon } from "assets/images/webBuilder/gallery-edit.svg";
import Locale from "translations";
import { useWebBuilderDispatch, useWebBuilderState } from "context/webBuilder";
import CustomLeftArrow from "modules/WebBuilder-V2/shared/CustomLeftArrow";
import CustomRightArrow from "modules/WebBuilder-V2/shared/CustomRightArrow";
import Carousel from "react-multi-carousel";
import ToolsModals from "./Modals/ToolsModals";
import { useState } from "react";
import usePreviewMode from "hooks/usePreviewMode";
import HeroContent from "./HeroContent";

export default function Hero() {
	// *** hooks
	const { commons } = Locale;
	const { hero, style } = useWebBuilderState();
	const dispatch = useWebBuilderDispatch();
	const { isPreview } = usePreviewMode();

	// *** states
	const [toolsIsOpen, setToolsIsOpen] = useState(false);

	function updateHeroContent({ name, value, type, language }) {
		dispatch({
			type: "updateHeroContent",
			payload: {
				name,
				value,
				type,
				language,
			},
		});
	}

	function updateSliderHero({ name, value, type }) {
		dispatch({
			type: "updateSliderContent",
			payload: {
				name,
				value,
				type,
			},
		});
	}

	const bgImage = hero?.mainContent?.backgroundImage ?? "";
	const sliders = hero?.mainContent?.sliders ?? [];
	const hasSlider = hero?.mainContent?.hasSlider;

	const responsive = {
		superLargeDesktop: {
			breakpoint: { max: 4000, min: 3000 },
			items: 1,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 1,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 1,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
		},
	};

	const toolToggle = () => setToolsIsOpen(!toolsIsOpen);

	const heroWithoutDots = ["theme-7", "theme-5"];
	const showDots = !heroWithoutDots.includes(style.theme);

	return (
		<div className="wb-hero">
			<div className="hero-container">
				{/* render slider */}
				{hasSlider ? (
					<Carousel
						responsive={responsive}
						itemClass={"web-builder-slide"}
						slidesToSlide={1}
						keyBoardControl={true}
						customRightArrow={<CustomRightArrow />}
						customLeftArrow={<CustomLeftArrow />}
						removeArrowOnDeviceType={["tablet", "mobile"]}
						renderButtonGroupOutside={false}
						showDots={showDots}
						infinite={true}
					>
						{sliders.map((slide) => {
							return (
								<img src={slide.src || slide} key={slide.id || slide} alt="" />
							);
						})}
					</Carousel>
				) : null}

				{/* render background */}
				{!hasSlider && (
					<div
						className="hero-bg"
						style={{
							backgroundImage: `url(${bgImage?.src ? bgImage.src : bgImage})`,
						}}
					></div>
				)}

				<div className="hero-content-container">
					<HeroContent updateHero={updateHeroContent} />
				</div>
			</div>

			{!isPreview && (
				<>
					<div className="update-hero-layout">
						<button
							className="hero-content-layout"
							style={{ borderRight: "1px solid #DDDCDC" }}
							// onClick={mainToggle}
							onClick={toolToggle}
						>
							<EditIcon />
						</button>
						<button className="hero-content-layout" onClick={toolToggle}>
							<GalleryEditIcon />
							<span className="mx-1">{commons.changeImage}</span>
						</button>
					</div>
					{toolsIsOpen &&
						<ToolsModals
							isOpen={toolsIsOpen}
							toggle={toolToggle}
							updateHeroContent={updateHeroContent}
							updateSliderHero={updateSliderHero}
							hasSlider={hasSlider}
							slides={sliders}
						/>
					}
				</>
			)}
		</div>
	);
}
