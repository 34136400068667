import { useEffect, useState } from 'react';
// React-Router-dom
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
// Translations
import Locale from 'translations';
// View
import ServicesList from './view/ServicesList';
// Components
import Pagination from 'components/Pagination';
// Shared 
import ServiceBuilderHeader from './shared/Header'
import ServiceBuilderSearchFilter from './shared/SearchFilter';
// Services
import { deleteServiceById, getAllServicesList, updateServiceStatus } from 'services/serviveBuilder';
// Assets
import { ReactComponent as AddIcon } from "assets/images/serviceBuilder/add.svg";
// -----------------------------------------------------------------------------------------------

const ServicesBuilder = () => {

  // *** Hooks
  const history = useHistory();
  const { bundle } = useParams();
  const { Service_Builder } = Locale;

  // *** State
  const [servicesList, setServicesList] = useState([])
  const [meta, setMeta] = useState({});
  const [filters, setFilters] = useState({
    name: null,
    page: 1,
    type: null,
    status: null,
  });


  const defualtServiceName = 'hotel'

  // *** Funcyion Logic
  const handleOnFilter = (filterObj) => {
    setFilters((prevFilter) =>
    ({
      ...prevFilter,
      name: filterObj?.productName || null,
      type: filterObj?.tag || null,
      status: filterObj?.status?.id || null,
    }))
  }

  function goTo(page) {
    setFilters((prevFilter) => ({ ...prevFilter, page }));
  }

  const getServices = async (params) => {
    const response = await getAllServicesList(params)
    setServicesList(response?.data?.data)
    setMeta(response?.data?.meta)
  }

  const handleDeleteService = async (id) => {
    const response = await deleteServiceById(id)
    if (response?.status === 200) {
      getServices(filters)
    }
  }

  const handleServiceStatus = async (params) => {
    const response = await updateServiceStatus({
      id: params?.id,
      status: params?.status
    })
    // console.log(response)
    if (response?.status === 200) {
      getServices(filters)
    }
  }

  useEffect(() => {
    getServices(filters)
  }, [filters])

  // ----------- JSX Code ---------
  return (
    <div className='service-builder-holder '>

      <ServiceBuilderHeader title={Service_Builder?.title}>
        <button
          className='service-action-btn'
          onClick={() => history.push(`/${bundle}/service-builder/${defualtServiceName}/add`)}
        >
          <AddIcon />
          {Service_Builder?.add_service}
        </button>
      </ServiceBuilderHeader>

      <ServiceBuilderSearchFilter onSearch={(filterObj) => handleOnFilter(filterObj)} />

      <ServicesList
        servicesList={servicesList}
        onDelete={(id) => handleDeleteService(id)}
        onUpdateStatus={(params) => handleServiceStatus(params)}
      />

      <div className="mt-4 pb-3">
        <Pagination info={meta} goTo={goTo} />
      </div>

    </div>
  )
}

export default ServicesBuilder
