import React, { useState } from 'react';
import theme1Src from "assets/images/webBuilder/template/1.png"
import theme2Src from "assets/images/webBuilder/template/2.png"
// import theme3Src from "assets/images/webBuilder/template/3.jpg"
import theme4Src from "assets/images/webBuilder/template/4.png"
import theme5Src from "assets/images/webBuilder/template/5.png"
import theme7Src from "assets/images/webBuilder/template/7.png";
// import theme10Src from "assets/images/webBuilder/template/10.jpg";
import { useWebBuilderDispatch, useWebBuilderState } from 'context/webBuilder';
// import { AlertCircleIcon } from 'modules/UmrahOperations/shared/Icons'
// import { Modal, ModalBody } from 'reactstrap';
// import { packagesUpdateStatus, servicesUpdateStatus, setPageBuilder } from 'services/webBuilder';
import { useParams } from 'react-router-dom';
import template1 from "../../Templates/template1.json";
import template2 from "../../Templates/template2.json";
// import template3 from "../../Templates/template3.json";
import template4 from "../../Templates/template4.json";
import template5 from "../../Templates/template5.json";
import template7 from "../../Templates/template7.json";
// import template10 from "../../Templates/template10.json";
// import { changeBorder, changeColorTheme, changeFont } from 'modules/WebBuilder-V2/helpers/styles';
import SelectLanguagesModal from 'modules/WebBuilder-V2/shared/ChooseLanguagesModal';
import { languagesLookup } from 'modules/WebBuilder-V2/ChooseTemplate';
import { formatPageData } from 'modules/WebBuilder-V2/helpers/formatPageData';
// import { store } from 'react-notifications-component';
// import Locale from 'translations';

export default function ChangeTheme() {
  // const { commons } = Locale;
  // const history = useHistory();
  const webBuilderData = useWebBuilderState();
  const { bundle } = useParams();

  const themes = [
    // {
    //   id: "old", name: "Customize your website", src: imageCustomize, initialData: templateOld, url: `/${bundle}/web-builder-v1/edit`
    // },
    { id: "theme-1", name: "Theme - Style 1", src: theme1Src, initialData: template1, url: `/${bundle}/web-builder-v2/edit` },
    { id: "theme-2", name: "Theme - Style 2", src: theme2Src, initialData: template2, url: `/${bundle}/web-builder-v2/edit` },
    // { id: "theme-3", name: "Theme - Style 3", src: theme3Src, initialData: template3, url: `/${bundle}/web-builder-v2/edit` },
    { id: "theme-4", name: "Theme - Style 4", src: theme4Src, initialData: template4, url: `/${bundle}/web-builder-v2/edit` },
    { id: "theme-5", name: "Theme - Style 5", src: theme5Src, initialData: template5, url: `/${bundle}/web-builder-v2/edit` },
    { id: "theme-7", name: "Theme - Style 7", src: theme7Src, initialData: template7, url: `/${bundle}/web-builder-v2/edit` },
    // { id: "theme-10", name: "Theme - Style 10", src: theme10Src, initialData: template10, url: `/${bundle}/web-builder-v2/edit` },
  ];


  // const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  // const [selectedTheme, setSelectedTheme] = useState(null);
  const [languagesModal, setLanguagesModal] = useState({
    isOpen: false,
    languagesList: [],
    defaultLanguage: null
  });

  const dispatch = useWebBuilderDispatch();
  const chosenTheme = themes.find(theme => webBuilderData?.style?.theme === theme.id);

  function toggleLanguagesModal(selectedLanguages, defaultLanguage) {
    setLanguagesModal({
      isOpen: !languagesModal.isOpen,
      languagesList: selectedLanguages || [],
      defaultLanguage: defaultLanguage || null
    })
  }

  // function updateStyle({ name, value }) {
  //   dispatch({
  //     type: 'updateStyle',
  //     payload: {
  //       name,
  //       value
  //     }
  //   })
  // }

  // function toggleConfirmModal() {
  //   setConfirmModalOpen(!confirmModalOpen);
  // }

  function chooseLanguages() {
    const languagesList = languagesModal.languagesList;
    const page_builder_config = formatPageData({
      contentData: webBuilderData,
      languagesList,
      defaultLanguage: languagesModal.defaultLanguage,
      themeConfig: chosenTheme.initialData
    })
    dispatch({
      type: 'setInitialData',
      payload: page_builder_config
    });
    toggleLanguagesModal(null)
  }

  // function getSourceBuilderItems() {
  //   const sectionsHasCards = ["packages", "hotels", "tours", "flights", "transfer", "otherService"];

  //   if (!webBuilderData?.content) {
  //     return { packageBuilderItems: [], serviceBuilderItems: [] };
  //   }

  //   return webBuilderData.content.reduce((acc, section) => {
  //     if (!sectionsHasCards.includes(section?.type)) {
  //       return acc;
  //     }

  //     const { items = [] } = section;
  //     const packageBuilderItems = items
  //       .filter(item => item?.product_uuid && item?.sourceModule === "package-builder")
  //       .map(item => item.id);

  //     const serviceBuilderItems = items
  //       .filter(item => item?.product_uuid && item?.sourceModule === "service-builder")
  //       .map(item => item.id);

  //     acc.packageBuilderItems.push(...packageBuilderItems);
  //     acc.serviceBuilderItems.push(...serviceBuilderItems);

  //     return acc;
  //   }, { packageBuilderItems: [], serviceBuilderItems: [] });
  // }

  // async function updateBuilderItemsStatus() {
  //   const { packageBuilderItems, serviceBuilderItems } = getSourceBuilderItems();
  //   const packagesIds = { add: [], remove: packageBuilderItems };
  //   const servicesIds = { add: [], remove: serviceBuilderItems };
    
  //   if (packagesIds.remove.length > 0 || servicesIds.remove.length > 0) {
  //     try {
  //       await Promise.all([
  //         packagesUpdateStatus(packagesIds),
  //         servicesUpdateStatus(servicesIds)
  //       ]);
  //       confirmSelectNewTheme();
  //     } catch {
  //       showNotification("danger", commons.somethingWentWrong);
  //     }
  //   } else {
  //     confirmSelectNewTheme();
  //   }
  // }

  // function showNotification(type, message) {
  //   store.addNotification({
  //     title: "",
  //     message,
  //     type,
  //     insert: "top",
  //     container: "top-right",
  //     animationIn: ["animated", "fadeIn"],
  //     animationOut: ["animated", "fadeOut"],
  //     dismiss: {
  //       duration: 5000,
  //       onScreen: true,
  //     },
  //   });
  // }

  // async function confirmSelectNewTheme() {
  //   // if (!selectedTheme) return;

  //   // const { languages, defaultLanguage } = webBuilderData;
  //   // const pageBuilderConfig = formatPageData({
  //   //   contentData: selectedTheme.initialData,
  //   //   languagesList: languages,
  //   //   defaultLanguage,
  //   //   themeConfig: selectedTheme.initialData
  //   // });

  //   // const res = await setPageBuilder({ page_builder_config: pageBuilderConfig });
  //   // if (res?.status === 200) {
  //   //   dispatch({
  //   //     type: 'setInitialData',
  //   //     payload: pageBuilderConfig
  //   //   });

  //   //   const { style } = pageBuilderConfig;
  //   //   changeColorTheme(style?.color, updateStyle);
  //   //   changeBorder(style?.rounded, updateStyle);
  //   //   changeFont(style?.font, updateStyle);
  //   //   toggleConfirmModal();
  //   //   history.push(selectedTheme.url);
  //   // }
  // }


  return (
    <div className="sidebar-theme">
      <div className="content-header">
        <h4>Thems</h4>
      </div>
      <div className="themes-container">
        <div className={`theme-wrapper  active`}>
          <img src={chosenTheme?.src} alt={chosenTheme?.name} />
          <p className="mt-2 h6 ps-2">{chosenTheme?.name}</p>
          <div className="overlay">
              <button className="btn"
                onClick={() => {
                  // setSelectedTheme(webBuilderData?.style?.theme);
                  toggleLanguagesModal(webBuilderData.languages, webBuilderData.defaultLanguage)
                }}
              >
                Choose languages
              </button>

          </div>
        </div>
        {/* {themes.map(theme => {
          const isSelected = webBuilderData?.style?.theme === theme.id;
          return (
            <div key={theme.id} className={`theme-wrapper ${isSelected ? "active" : "pointer"}`}>
              <img src={theme.src} alt={theme.name} />
              <p className="mt-2 h6 ps-2">{theme.name}</p>
              <div className="overlay">
                {isSelected ?
                  <button className="btn"
                    onClick={() => {
                      setSelectedTheme(theme);
                      toggleLanguagesModal(webBuilderData.languages, webBuilderData.defaultLanguage)
                    }}
                  >
                    Choose languages
                  </button>
                  :
                  <button className="btn"
                    onClick={() => {
                      setSelectedTheme(theme);
                      toggleConfirmModal();
                      // toggleLanguagesModal(webBuilderData.languages, webBuilderData.defaultLanguage)
                    }}
                  >
                    Change theme
                  </button>
                }

              </div>
            </div>
          )
        })} */}

      </div>


      {/* {confirmModalOpen ?
        <Modal className="confirm-theme-modal" isOpen={confirmModalOpen} centered>
          <ModalBody>
            <div className="d-flex flex-column gap-10 align-items-start">
              <span className="icon mb-2">
                <AlertCircleIcon color="#D92D20" />
              </span>
              <h6 className="header">Change Theme</h6>
              <p className="info-text">Change theme will delete all your saved data. </p>
              <p className="font-weight-bold">Are you sure changing theme?</p>
            </div>
            <div className="d-flex gap-10 mt-4">
              <button className="btn reject-btn" onClick={toggleConfirmModal}>No</button>
              <button className="btn confirm-btn" onClick={updateBuilderItemsStatus}>Yes</button>
            </div>
          </ModalBody>
        </Modal>

        :
        null
      } */}

      <SelectLanguagesModal
        isOpen={languagesModal.isOpen}
        toggle={toggleLanguagesModal}
        setLanguagesModal={setLanguagesModal}
        languagesList={languagesModal.languagesList}
        defaultLanguage={languagesModal.defaultLanguage}
        selectTemplateConfig={chooseLanguages}
        languagesLookup={languagesLookup}
      />
    </div>
  )
}
