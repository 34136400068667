import { useParams, Link } from "react-router-dom";
import Locale from 'translations';
import RequestSharedHotelForm from './shared/RequestHotelForm';
import RequestSharedOtherForm from './shared/RequestOtherForm';
import NoSegmentMatch from "assets/images/rquests/segment-no-match.svg"

export default function RequestForm() {

  const { requests } = Locale;
  const { type } = useParams();

  return (
    <div className="request__page-container">
      {
        (type === 'hotels' || type === 'flights' || type === 'transporters') ? (
          <div className="card border-0 mb-3">
            <div className="card-body">
              <div className="card-title d-flex gap-2 mb-0">
                <Link className='bg-transparent safa-text-primary p-0 border-0' to={`/requests/${type}`} >
                  <i className='fa fa-chevron-left mirror-rtl'></i>
                </Link>
                <h1 className="font-weight-bold fs-lg mb-0">{requests.makeRequest?.[type]}</h1>
              </div>
              <div className="navigation__links justify-content-start mt-4">
                <Link to={`/requests/hotels/new-request`} class={`navigation__link ${type === 'hotels' ? 'active' : ''}`}>{requests.hotels}</Link>
                <Link to={`/requests/flights/new-request`} class={`navigation__link ${type === 'flights' ? 'active' : ''}`}>{requests.flights}</Link>
                <Link to={`/requests/transporters/new-request`} class={`navigation__link ${type === 'transporters' ? 'active' : ''}`}>{requests.transporters}</Link>
              </div>
              <div className="card-content p-4 bg-light rounded-md mt-4">
                {
                  type === 'hotels' ? (
                    <RequestSharedHotelForm type={type} />
                  ) : (
                    <RequestSharedOtherForm type={type} />
                  )
                }

              </div>
            </div>
          </div>
        ) : (
          <div className='card border-0'>
            <div className="card-body d-flex flex-column justify-content-center align-items-center p-lg-5 p-4">
              <div className="icon mb-3">
                <img width={250} height={'auto'} src={NoSegmentMatch} alt="No Segment Matches" />
              </div>
              <div className="text">
                <p className="fw-600 my-0 mx-auto text-center" style={{
                  maxWidth: '450px'
                }}>{requests?.segmentMismatch}</p>
              </div>
            </div>
          </div>
        )
      }
    </div>
  )

}