import LanguageSwitcher from "components/LanguageSwitcher/LanguageSwitcher";
import UserSettings from "components/UserSettings";
import { useSBSDispatch, useSBSState } from "context/global";
import { useEffect, useRef, useState } from "react";
import { getPermissions, getSubscripedModules, uploadFile } from "services/auth";
import { NotifactionHeader } from "./notifactionHeader";
import WalletHeader from "./walletHeader";
import ShowForPermission from "helpers/showForPermission";
import CurrencySwitcher from "./CurrencySwitcher";
import LanguageSwitcherMobile from "components/LanguageSwitcher/LanguageSwitcherMobile";
import UserSettingsMobile from "components/UserSettingsMobile";
import WalletHeaderMobile from "./walletHeaderMobile";
import CurrencySwitcherMobile from "./CurrencySwitcherMobile";
import { NotifactionHeaderMobile } from "./notifactionHeaderMobile";
import SafaLogo from "assets/images/dashboard/safaLogoFull.svg";
import { Link } from 'react-router-dom';
import { store } from 'react-notifications-component';
import Locale from 'translations';
import imgIcon from "../../assets/images/customRegister/image-regular.svg";
// import { ReactComponent as EditIcon } from "assets/images/umrah-operations/edit-icon.svg";
import { fetchCompanyProfile, updateCompanyLogo } from 'services/profile';


export default function Header() {
	const URI = process.env.REACT_APP_FILEUPLOAD_URL;
	const search = window.location.search;
	const name = new URLSearchParams(search);
	const dispatch = useSBSDispatch();
	const inputFileRef = useRef(null);
	const { companyAvatar, commons } = Locale;
	const { pathname } = window.location;

	const { isAuth, token_data, token, companyInfo } = useSBSState();
	const isServiceCompnay = companyInfo?.company_category === "services_company"
	const [width, setWidth] = useState(window.innerWidth);
	const [companyProfileState, setCompanyProfileState] = useState({
		companyLogo: null,
		filePreview: null,
	});

	const handleWindowSizeChange = () => {
		setWidth(window.innerWidth);
	};

	async function fetchCompanyData() {
		const company = await fetchCompanyProfile();
		const fetchImage = `${URI}/fetch/${company.logo}`;
		setCompanyProfileState({
			filePreview: fetchImage,
			avatarID: company.logo
				&& company.logo
		});

	}

	const handleBtnClick = () => {
		inputFileRef.current.click();
	};

	const onFileChange = async (e) => {
		const fileType = e.target.files[0];
		const formData = new FormData();
		formData.append("file", fileType);
		formData.append("name", fileType.name);
		formData.append("bucket", "company");
		if (!fileType.name.match(/.(jpg|jpeg|png|gif|svg)$/i)) {
			store.addNotification({
				title: commons.somethingWentWrong,
				message: companyAvatar.companyAvatarExtensionValidation,
				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		} else if (fileType.size > 500000) {
			store.addNotification({
				title: commons.somethingWentWrong,
				message: companyAvatar.companyAvatarSizeValidation,
				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		} else {
			const response = await uploadFile(formData);
			if (response.status === 200) {
				setCompanyProfileState({
					...companyProfileState,
					avatar: response.data.data.uuid,
				});
				const updatedLogoResponse = await updateCompanyLogo({ logo: response.data.data.uuid });
				if (updatedLogoResponse?.status === 200){
					const fetchImage = `${URI}/fetch/${response.data.data.uuid}`;
					setCompanyProfileState({
						filePreview: fetchImage,
						avatarID: companyProfileState.avatarID
							? companyProfileState.avatarID
							: response.data.data.uuid,
					});
				}
				
			}
		}
	};

	useEffect(() => {
		async function fetchPermissions() {
			const res = await getPermissions();

			dispatch({ type: "getPermissions", payload: res?.data?.data });
		}
		let x = {};
		for (const [key, value] of name) {
			x = { ...x, [key]: value };
		}

		if (x.hasOwnProperty("token") && token != null) {
			fetchPermissions();
		} else if (!x.hasOwnProperty("token")) {
			fetchPermissions();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		async function fetchSubsciptionPlan() {
			const res = await getSubscripedModules();
			if (res?.status === 200) {
				dispatch({ type: "subscriptionPlan", payload: res?.data?.data });
			}
		}
		fetchSubsciptionPlan();
		if (isServiceCompnay && !pathname?.includes('company-profile') ) {
			fetchCompanyData()
		}
	}, [])

	// const getData = async () => {
	// 	const response = await fetch("https://ipapi.co/json/");
	// 	const data = await response.json();
	// 	localStorage.setItem("IPv4", data.ip);
	// };


	// useEffect(() => {
	// 	async function getActivePlan() {
	// 		let res = await fetchsubscriptionActivePlan();
	// 		if (res?.status === 200 && res?.data?.data?.is_free_trial) {
	// 			setActivePlan(res?.data?.data);
	// 		}
	// 	}
	// 	if (!activePlan && companyInfo?.company_category === "umrah_company") {
	// 		getActivePlan();
	// 	}
	// }, [activePlan, companyInfo?.company_category])

	useEffect(() => {
		const getData = async () => {
			const response = await fetch("https://ipapi.co/json/");
			const data = await response.json();
			localStorage.setItem("IPv4", data.ip);
		};
		getData();
	}, []);

	useEffect(() => {
		window.addEventListener("resize", handleWindowSizeChange);
		return () => {
			window.removeEventListener("resize", handleWindowSizeChange);
		};
	}, []);

	// console.log(pathname)

	return (
		<header className="navbar bg-white" style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
			<div className="container-fluid">
				<Link to="/dashboard">
					<img src={SafaLogo} alt="safa soft logo" width="195px" />
				</Link>

				{width >= 320 && width <= 600 ? (
					<div className="dropdowns d-flex align-items-center" style={{ gap: "5px" }}>
						{!isServiceCompnay && <CurrencySwitcherMobile className="bg-white" />}

						{!search.includes("token") ? (
							<>
								{!isServiceCompnay &&
									<ShowForPermission
										permission={["View-Wallets", "Manage-Wallets"]}
									>
										<WalletHeaderMobile />
									</ShowForPermission>
								}
								<NotifactionHeaderMobile />
							</>
						) : token_data && token_data.discount != null ? (
							<p style={{ color: "#D92626" }} className="mx-2">
								<strong style={{ fontWeight: "700" }}>
									{token_data.discount}%
								</strong>{" "}
								OFF
							</p>
						) : (
							""
						)}

						<LanguageSwitcherMobile show={false} />
						{isAuth ? <UserSettingsMobile /> : null}
					</div>
				) : (
					<div className="dropdowns d-flex align-items-center">

						{/* company logo */}
						{isServiceCompnay && 
								<div className="col-md-3 col-12">
									<input
										type="file"
										ref={inputFileRef}
										onChange={onFileChange}
										className="d-none"
										accept="image/*"
									/>
									<div className="image-uploader">
										<div className="image-uploader__outline position-relative bg-white img-center mx-auto mx-md-0">
											<div className="img-holder ">
												<img
													src={
														companyProfileState?.filePreview
															? companyProfileState.filePreview
															: imgIcon
													}
													className={"img-fluid"}
													accept="image/*"
													alt='logo'
												/>
											</div>
											<i onClick={handleBtnClick} className="fas fa-plus-circle image-uploader__plus-btn"></i>
										</div>
									</div>

								</div>
						}
						

						<LanguageSwitcher />

						{!isServiceCompnay && <CurrencySwitcher className="bg-white" />}

						{!search.includes("token") ? (
							<>
								{!isServiceCompnay &&
									<ShowForPermission
										permission={["View-Wallets", "Manage-Wallets"]}
									>
										<WalletHeader />
									</ShowForPermission>
								}
								{width >= 768 ? <NotifactionHeader /> : null}{" "}
							</>
						)
							: token_data && token_data.discount != null ? (
								<p style={{ color: "#D92626" }} className="mx-2">
									<strong style={{ fontWeight: "700" }}>
										{token_data.discount}%
									</strong>{" "}
									OFF
								</p>
							) : (
								""
							)}

						{isAuth ? <UserSettings /> : null}
					</div>
				)}
			</div>
		</header>
	);
}
