import PreAuthLayoutV2 from './Components/Layout';
import TextField from 'components/Form/TextField/TextField';
import PasswordField from 'components/Form/PasswordField/PasswordField';
import Locale from 'translations';
import SelectField from 'components/Form/SelectField/SelectField';
import { Link, useHistory } from 'react-router-dom';
// import PhoneField from 'components/Form/phonField/PhoneField';
import useFetchCountries from 'hooks/useFetchCountries';
import { useRef, useState } from 'react';
import validate, { isFormValid } from 'helpers/validate';
import { accountRegister, emailAvailability, nameAvailability } from 'services/auth';
import { useSBSState } from 'context/global';
import NumberField from 'components/Form/NumberField/NumberField';
import ReCAPTCHA from 'react-google-recaptcha';
import { handleResponesNotification } from 'modules/UmrahOperations/helper/accountingHelpers';
// import { fetchCities } from 'services/lookups';

const keysNotRequired = ["city", "phone", "phone_code"]
export default function RegistrationV2() {
  // ** hooks
  const { locale } = useSBSState();
  const { userDetails, login, companyDetails, statistics } = Locale;
  const [countries] = useFetchCountries();
  const history = useHistory();

  const recaptcha = useRef(null);

  // ** states
  const [registrationData, setRegistrationData] = useState({
    name: "",
    email: "",
    password: "",
    passwordConfirmation: "",
    country: null,
    city: null,
    phone: "",
    phone_code: "",
  });

  const [errors, setErrors] = useState({});
  // const [cities, setCities] = useState([])

  const isPasswordMatch = registrationData.passwordConfirmation === registrationData.password;

  // ** functions
  function handleInputChanges({ key, value }) {
    let registrationDataClone = { ...registrationData }
    registrationDataClone[key] = value;
    setRegistrationData(registrationDataClone);
  }

  // async function fetchCityByCountryId(countryId) {
  //   const cities = await fetchCities(countryId);
  //   setCities(cities);
  // };

  function checkFormErrors() {
    let submitError = {};
    Object.keys(registrationData).forEach(key => {
      if (!keysNotRequired.includes(key)) {
        submitError = {
          ...submitError,
          ...validate(
            { name: key, value: key === "passwordConfirmation" ? isPasswordMatch : registrationData[key] },
            {
              required: true,
              email: key === "email",
              password: key === "password",
              min: key === "password" ? 8 : key === "name" || key === "companyName" ? 3 : "",
              confirm: key === "passwordConfirmation"
            }
          )
        }
      }
    })
    setErrors(() => submitError);
    return submitError;
  }

  async function submit(e) {
    e.preventDefault();
    let formErrors = checkFormErrors();
    if (!recaptcha.current.getValue()) {
      handleResponesNotification({ alertType: "danger", title: "Error!", message: "Please Submit Captcha" })
    }
    if (isFormValid(formErrors)) {
      const emailData = { user_email: registrationData.email };
      const companyData = {
        lang: locale,
        name: registrationData?.name,
        country_id: registrationData?.country?.id,
      }

      // check if comapny name and email is valid
      const responses = await Promise.all([emailAvailability(emailData), nameAvailability(companyData)]);
      if (responses.every(response => response?.status === 200)) {


        let data = {
          user_full_name: registrationData?.name,
          user_email: registrationData?.email,
          user_nationality: registrationData?.country.value,
          user_phone: registrationData?.phone ? registrationData?.phone_code?.phone_code + registrationData?.phone : null,
          user_password: registrationData?.password,
          user_password_confirmation: registrationData?.passwordConfirmation,
          company_name: registrationData?.name,
          lang: locale,
          company_business_email: registrationData?.email,
          company_country_id: registrationData?.country?.id?.toString(),
          company_phone: registrationData?.phone ? registrationData?.phone_code?.phone_code + registrationData?.phone : null,
        }
        const response = await accountRegister(data);
        if (response.status === 200) {
          history.push("/auth/register_safa/success");
        }
      }
    }

  }

  return (
    <PreAuthLayoutV2>
      <>
        <div className="form-header">
          <h2>{login.signup}</h2>
          <h3>{login.loginMessage}</h3>
        </div>
        <form onSubmit={submit} className="mt-4">
          <div className="row mx-0">
            {/* company name */}
            <div className="col-12">
              <TextField
                type="text"
                label={companyDetails.companyName}
                placeholder={companyDetails.companyNamePlaceholder}
                name="companyName"
                id="safa_register_companyname"
                min={3}
                value={registrationData.name}
                onChange={(e) => {
                  handleInputChanges({ key: "name", value: e.target.value });
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "name", value: e.target.value },
                      { required: true, min: 3 }
                    ),
                  });
                }}
                color={errors?.name?.required || errors?.name?.min ? "danger" : ""}
                errors={errors?.name}
              />
            </div>

            {/* email address */}
            <div className="col-12 mt-3">
              <TextField
                type="email"
                label={login.emailAddress}
                placeholder={login.emailPlaceholder}
                name="email"
                id="safa_register_email"
                value={registrationData.email}
                onChange={(e) => {
                  handleInputChanges({ key: "email", value: e.target.value });
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "email", value: e.target.value },
                      { required: true, email: true }
                    ),
                  });
                }}
                color={errors?.email?.required || errors?.email?.email ? "danger" : ""}
                errors={errors?.email}
              />
            </div>

            {/* password */}
            <div className="col-md-6 col-12 mt-3">
              <PasswordField
                hasLabel={true}
                label={login.password}
                placeholder={login.passwordPlaceholder}
                name="password"
                id="safa_register_password"
                min={8}
                value={registrationData.password}
                onChange={(e) => {
                  handleInputChanges({ key: "password", value: e.target.value });
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "password", value: e.target.value },
                      { required: true, password: true, min: 8 }
                    ),
                  });
                }}
                color={errors?.password?.required || errors?.password?.password || errors?.password?.min ? "danger" : ""}
                errors={errors?.password}
              />
            </div>

            {/* password confirmation */}
            <div className="col-md-6 col-12 mt-3">
              <PasswordField
                hasLabel={true}
                label={userDetails.repeatPassword}
                placeholder={userDetails.repeatPasswordPlaceholder}
                name="passwordConfirmation"
                id="safa_register_passwordConfirmation"
                value={registrationData.passwordConfirmation}
                onChange={(e) => {
                  handleInputChanges({ key: "passwordConfirmation", value: e.target.value });
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "passwordConfirmation", value: e.target.value === registrationData.password },
                      { required: true, confirm: true }
                    ),
                  });
                }}
                color={
                  errors?.passwordConfirmation?.required || errors?.passwordConfirmation?.confirm
                    ? "danger"
                    : ""
                }
                errors={errors?.passwordConfirmation}
              />
            </div>

            {/* country */}
            <div className="col-12 mt-3">
              <SelectField
                label={companyDetails.country}
                placeholder={companyDetails.countryPlaceholder}
                name="country"
                id="safa_register_country"
                options={countries}
                value={registrationData.country?.name}
                onChange={(e) => {
                  handleInputChanges({ key: "phone_code", value: e });
                  handleInputChanges({ key: "country", value: e });
                  setRegistrationData({ ...registrationData, country: e, phone_code: e });
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "country", value: e },
                      { required: true, }
                    ),
                  });
                  // fetchCityByCountryId(e?.id)
                }}
                color={errors?.country?.required ? "danger" : ""}
                errors={errors?.country}
              />
            </div>
            {/* city */}
            {/* <div className="col-12 mt-3">
              <SelectField
                label={companyDetails.city}
                placeholder={companyDetails.cityPlaceholder}
                name="city"
                id="safa_register_city"
                options={cities}
                value={registrationData.city?.name}
                onChange={(e) => {
                  handleInputChanges({ key: "city", value: e });
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "city", value: e },
                      { required: false, }
                    ),
                  });
                }}
                disabled={!registrationData.country}
              />
            </div> */}

            <div className="col-12 mt-3">
              <NumberField
                type={"phone"}
                label={userDetails.phoneNumber}
                hasLabel={false}
                id="phone"
                name="phone"
                value={registrationData?.phone}
                removeArrow={true}
                placeholder={"000 0000 000"}
                min={3}
                onChange={(e) => {
                  handleInputChanges({ key: "phone", value: e.target.value });
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "phone", value: e.target.value },
                      { required: true, }
                    ),
                  });
                }}
                extraTextPosition="prepend"
                extraText={registrationData?.phone_code?.phone_code}
                color={errors?.phone?.required ? "danger" : ""}
                errors={errors?.phone}
              />
              {/* <PhoneField
                label={companyDetails.companyPhoneNumber}
                countriesLookup={countries}
                phoneCode={registrationData.phone_code}
                phone={registrationData.phone}
                onSelectPhoneCode={(e) => {
                  handleInputChanges({ key: "phone_code", value: e });
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "phone_code", value: e },
                      { required: true, }
                    ),
                  });
                }}
                onChangePhone={(e) => {
                  handleInputChanges({ key: "phone", value: e.target.value });
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "phone", value: e.target.value },
                      { required: true, }
                    ),
                  });
                }}
                phoneCodeColor={errors?.phone_code?.required ? "danger" : ""}
                phoneCodeError={errors?.phone_code}
                phoneColor={errors?.phone?.required ? "danger" : ""}
                phoneError={errors?.phone}
              /> */}
            </div>

            <div className="col-12 mt-2">
              <ReCAPTCHA sitekey={process.env.REACT_APP_ReCAPTCHA_SITE_KEY} ref={recaptcha} className="w-100"/>
            </div>

            <div className="col-12 d-flex flex-column justify-content-center gap-10 mt-3">
              <button type="submit" className="btn main-btn">
                <span className="btn-overlay"></span>
                <span className="text">{login.signup}</span>
              </button>

              {/* <Link to="/" className="btn secondary-btn">
                <span className="btn-overlay"></span>
                <span className="text">{login.signupWithSafaKey}</span>
              </Link> */}

              <span className="text-center">
                {login.alreadyHaveAccount} | {" "}
                <Link to="/auth/login">
                  {statistics.signIn}
                </Link>
              </span>
            </div>

          </div>
        </form>



      </>
    </PreAuthLayoutV2>
  )
}
