import { Fragment } from 'react';
import Modal from 'react-modal';
import Locale from 'translations';
import { ReactComponent as CloseIcon } from "assets/images/serviceBuilder/x.svg";
import { useEffect, useState } from 'react';

export default function RequestViewModal({ type, open, closeModal, viewId, currentView }) {
  const { requests } = Locale;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!viewId || !currentView) {
      closeModal();
      setLoading(false);
    }
  }, [viewId, currentView])

  return (
    <Modal
      isOpen={open}
      contentLabel="View Request Details"
      ariaHideApp={false}
      onRequestClose={closeModal}
      overlayClassName={'request__page-modal'}
    >
      {
        loading && (
          <div className="loader__container">
            <span className="css-spinner dark"></span>
          </div>
        )
      }
      <div className="wrapper text-start">
        <div className="modal-header d-flex gap-2 flex-wrap justify-content-between">
          <h4 className='fs-lg font-weight-bolder text-capitalize'>
            {type === 'hotels' ? requests.hotelRequestDetails : (type === 'flights' ? requests.flightRequestDetails : requests.transporterRequestDetails)}
            <span className="mx-1"></span>
            {
              currentView?.status === 'new' && (
                <span className='safa-badge badge-warning'>{requests.requestStatus.pending}</span>
              )
            }
            {
              currentView?.status === 'approved' && (
                <span className='safa-badge badge-success'>{requests.requestStatus.approved}</span>
              )
            }
            {
              currentView?.status === 'rejected' && (
                <span className='safa-badge badge-danger'>{requests.requestStatus.rejected}</span>
              )
            }
          </h4>
          <button className='bg-transparent border-0 p-0' type='button' onClick={closeModal}>
            <CloseIcon />
          </button>
        </div>
        <div className="modal-body-content py-4">
          {
            currentView?.reject_reason ? (
              <div className="alert alert-danger fs-sm">
                <p className="my-0 font-weight-bolder">{requests.rejectionReason}</p>
                <p className='mb-0 mt-1'>
                  {currentView.reject_reason}
                </p>
              </div>
            ) : ''
          }
          <div className={`inner__content ${currentView?.reject_reason ? 'mt-4' : 'mt-2'}`}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6 mb-3">
                  <div className="item">
                    <p className="text-muted fs-xs my-0">{ type === 'hotels' ? requests.hotelName : (type === 'flights' ? requests.flightName : requests.transporterName )}</p>
                    {
                      type === 'hotels' && (
                        <p className="mt-1 mb-0 fs-md fw-600">
                          {currentView?.hotel_name ? currentView?.hotel_name : '-'}
                        </p>
                      )
                    }
                    {
                      (type === 'flights' || type === 'transporters') && (
                        <p className="mt-1 mb-0 fs-md fw-600">
                          {currentView?.name ? currentView?.name : '-'}
                        </p>
                      )
                    }
                  </div>
                </div>
                {
                  type === 'hotels' && (
                    <Fragment>
                      <div className="col-sm-6 mb-3">
                        <div className="item">
                          <p className="text-muted fs-xs my-0">{requests.hotelRate}</p>
                          <p className="mt-1 mb-0 fs-md fw-600">
                            {currentView?.rating ? (currentView?.rating + ' Stars') : '-'}
                          </p>
                        </div>
                      </div>
                      {
                        currentView?.phone ? (
                          <div className="col-sm-6 mb-3">
                            <div className="item">
                              <p className="text-muted fs-xs my-0">{requests.phone}</p>
                              <p className="mt-1 mb-0 fs-md fw-600" dir='ltr'>
                                <span>{currentView?.phone_code ? currentView?.phone_code : ''}</span>
                                <span>{currentView?.phone ? currentView.phone : '-'}</span>
                              </p>
                            </div>
                          </div>
                        ) : ""
                      }
                    </Fragment>
                  )
                }
                <div className="col-sm-6 mb-3">
                  <div className="item">
                    <p className="text-muted fs-xs my-0">{requests.country}</p>
                    <p className="mt-1 mb-0 fs-md fw-600">
                      {currentView?.country?.name ? currentView?.country?.name : '-'}
                    </p>
                  </div>
                </div>
                {
                  type === 'hotels' && (
                    <Fragment>
                      <div className="col-sm-6 mb-3">
                        <div className="item">
                          <p className="text-muted fs-xs my-0">{requests.city}</p>
                          <p className="mt-1 mb-0 fs-md fw-600">
                            {currentView?.city?.name ? currentView?.city?.name : '-'}
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-6 mb-3">
                        <div className="item">
                          <p className="text-muted fs-xs my-0">{requests.address}</p>
                          <p className="mt-1 mb-0 fs-md fw-600">
                            {currentView?.address ? currentView?.address : '-'}
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-6 mb-3">
                        <div className="item">
                          <p className="text-muted fs-xs my-0">{requests.latitude}</p>
                          <p className="mt-1 mb-0 fs-md fw-600">
                            {currentView?.latitude ? currentView?.latitude : '-'}
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-6 mb-3">
                        <div className="item">
                          <p className="text-muted fs-xs my-0">{requests.longitude}</p>
                          <p className="mt-1 mb-0 fs-md fw-600">
                            {currentView?.longitude ? currentView?.longitude : '-'}
                          </p>
                        </div>
                      </div>
                      {
                        currentView?.amenities?.length ? (
                          <div className="col-12 mb-3">
                            <div className="item">
                              <p className="text-muted fs-xs my-0">{requests.hotelAmenities}</p>
                              <div className="tags mt-2 d-flex flex-wrap gap-sm">
                                {
                                  currentView?.amenities?.map((el, index) => (
                                    <span className="safa-badge badge-success" key={index}>{el}</span>
                                  ))
                                }
                              </div>
                            </div>
                          </div>
                        ) : ''
                      }
                      <div className="col-12 mb-3">
                        <div className="item">
                          <p className="text-muted fs-xs my-0">{requests.hotelDesc}</p>
                          <p className="mt-1 mb-0 fs-md fw-600">
                            {currentView?.description ? currentView.description : "--"}
                          </p>
                        </div>
                      </div>
                    </Fragment>
                  )
                }
                {
                  type === 'hotels' && currentView?.images?.length ? (
                    <div className="col-12 mb-4">
                      <div className="item">
                        <p className="text-muted fs-xs my-0">{requests.requestImages}</p>
                        <div className="tags mt-3 d-flex flex-wrap gap-1">
                          {
                            currentView?.images?.map((im, index) => (
                              <a href={im} target='_blank' rel='noreferrer' key={index}>
                                <img className='rounded-sm' width={90} height={'auto'} src={im} alt='Request Images' />
                              </a>
                            ))
                          }
                        </div>
                      </div>
                    </div>
                  ) : ''
                }
                {
                  (type === 'flights' || type === 'transporters') && currentView?.logo ? (
                    <div className="col-12 mb-4">
                      <div className="item">
                        <p className="text-muted fs-xs my-0">{requests.requestImages}</p>
                        <div className="tags mt-3 d-flex flex-wrap gap-1">
                          <a href={currentView.logo} target='_blank' rel='noreferrer'>
                            <img className='rounded-sm' width={90} height={'auto'} src={currentView.logo} alt='Request Images' />
                          </a>
                        </div>
                      </div>
                    </div>
                  ) : ''
                }
                <div className="col-12 d-flex justify-content-end">
                  <button className='btn-Product-Filter bg-nxt mt-2' type='button' onClick={closeModal}>
                    {requests.close}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}