import axios from "axios";

const HOTEL_URL = process.env.REACT_APP_API_URL + "/v1/lookup-suggestions/hotels";
const FLIGHT_URL = process.env.REACT_APP_API_URL + "/v1/lookup-suggestions/flights";
const TRASNSPORTER_URL = process.env.REACT_APP_API_URL + "/v1/lookup-suggestions/transporters";

export const getHotelRequests = async (filter) => {
  return await axios.get(HOTEL_URL, {
    params: filter
  }).then(res => res).catch(err => err)
}

export const getFlightRequests = async (filter) => {
  return await axios.get(FLIGHT_URL, {
    params: filter
  }).then(res => res).catch(err => err)
}

export const getTransporterRequests = async (filter) => {
  return await axios.get(TRASNSPORTER_URL, {
    params: filter
  }).then(res => res).catch(err => err)
}

export const createHotel = async (body) => {
  return await axios.post(HOTEL_URL, body).then(res => res).catch(err => err)
}

export const createFlight = async (body) => {
  return await axios.post(FLIGHT_URL, body).then(res => res).catch(err => err)
}

export const createTransporter = async (body) => {
  return await axios.post(TRASNSPORTER_URL, body).then(res => res).catch(err => err)
}

export const getHotelRequest = async (id) => {
  return await axios.get(HOTEL_URL + `/${id}`).then(res => res).catch(err => err)
}

export const getFlightRequest = async (id) => {
  return await axios.get(FLIGHT_URL + `/${id}`).then(res => res).catch(err => err)
}

export const getTransporterRequest = async (id) => {
  return await axios.get(TRASNSPORTER_URL + `/${id}`).then(res => res).catch(err => err)
}