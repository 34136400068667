// Translation
import Locale from 'translations';
// ReactStarp
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
// Components
import CheckBox from 'components/chekbox';
// Langs Lookup
import { languagesLookup } from 'modules/WebBuilder-V2/ChooseTemplate';
// -----------------------------------------------------------------------
function LanguagesModal({
  isOpen,
  toggle,
  setLanguages,
  languageModalState,
  sendLanguages
}) {

  const { Service_Builder } = Locale

  // console.log(languageModalState?.selectedLangs)

  // --------- JSX Code ---------
  return (
    <Modal isOpen={isOpen} centered className="web-builder-languages-modal" >
      <ModalHeader toggle={toggle} className='web-builder-custom-modal-title'>
        <span className='d-block font-bold'>
          {Service_Builder?.languages}
        </span>
        <span className='d-block mt-2 text-gray-300' style={{ fontSize: '12px' }}>
          {Service_Builder?.selectLanguagesYouNeedToUseForYourWebsite}
        </span>
      </ModalHeader>

      <ModalBody>
        <div className="languages-list">
          {languagesLookup.map((language, index) => {
            const isChecked = languageModalState?.selectedLangs?.some(e => e  === language?.code)
            return (
              <div className="d-flex justify-content-between" key={language?.code}>
                <div className='checkbox'>
                  <CheckBox
                    checked={isChecked}
                    id={language.code}
                    value={language?.code}
                    name={language.code}
                    text={language.name}
                    onChange={(e) => {
                      console.log(e.target.value)
                      if (isChecked) {
                        const langs = languageModalState?.selectedLangs?.filter(item => item !== e.target.value)
                        setLanguages(langs)
                      } else {
                        setLanguages([...languageModalState?.selectedLangs, e.target.value])
                      }
                    }
                    }
                  />
                </div>
              </div>
            )
          })}
        </div>
        <button className="btn p-0 shadow-none mt-3 py-2 w-100 bg-nxt"
          disabled={!languageModalState?.selectedLangs?.length}
          onClick={sendLanguages}
        >
          {Service_Builder?.save}
        </button>
      </ModalBody>
    </Modal>
  )
}

export default LanguagesModal