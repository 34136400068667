import React from "react";
import CheckBox from "components/chekbox";
import { ReactComponent as CloseIcon } from "assets/images/product-builder/close-circle.svg";
import { deleteFileFromBucket } from 'services/webBuilder';


export default function Library({ handleUpload, libraryImages, setLibraryImages, handleSliderImages, toggle, hasSlider }) {

	function handleSelectSlide(uuid, checked) {
		const sliderClone = [...libraryImages].map(slide => ({
			...slide,
			inSlider: slide.id === uuid ? !checked : slide.inSlider
		}));
		setLibraryImages(sliderClone)
	}

	const saveBackgrounds = () => {
		handleSliderImages(libraryImages?.filter(slide => slide.inSlider));
		toggle();
	};



	async function handleDeleteSlide(uuid) {
		const res = await deleteFileFromBucket(uuid)
		if (res?.status === 200) {
			const sliderClone = [...libraryImages].filter(slide => slide.id !== uuid);
			setLibraryImages(sliderClone)
		}
	}

	return (
		<div className="web-builder-library">
			<div className="web-builder-library-images">
				{libraryImages?.map((image, idx) => {
					const isChecked = image?.inSlider;
					if (hasSlider) {
						return (
							<div key={`${image?.id}-${idx}`} className="form-check form-check-modal position-relative w-100">
								{!image.static && !isChecked &&
									<span className="position-absolute bg-white p-1 pointer" style={{ insetInlineEnd: "0" }}
										onClick={() => handleDeleteSlide(image?.id)}
									>
										<CloseIcon />
									</span>
								}
								<CheckBox
									key={idx}
									name="permissions"
									checked={isChecked}
									value={image?.id}
									text={
										<img
											src={image?.src}
											alt="background"
											width="100%"
											className={`gallery-img ${isChecked ? "b-multiSelect-image" : ""}`}
										/>
									}
									onChange={() => handleSelectSlide(image?.id, isChecked)}
								/>
							</div>
						)
					}
					return (
						<div key={`${image?.id}-${idx}`} className="form-check form-check-modal pointer"
							onClick={() => {
								handleUpload(image)
							}}
						>
							<img
								src={image?.src}
								alt="background"
								width="100%"
								className={`gallery-img`}
							/>
						</div>
					)
				})
				}
			</div>

			{hasSlider && (
				<div className="d-flex justify-content-end p-1 my-3">
					<button
						onClick={saveBackgrounds}
						className="btn btn-block w-25 p-1"
						style={{
							background: "#292d32",
							color: "#FFF",
							fontSize: "1rem",
						}}
						disabled={libraryImages?.filter(slide => slide.inSlider)?.length <= 1}
					>
						Save
					</button>
				</div>
			)}
		</div>
	);
};