import { useSBSState } from 'context/global';
import moment from 'moment';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { Link } from "react-router-dom";
import { DocumentDownloadIcon, EditIcon } from "../shared/Icons";
import Locale from 'translations';
import { useState } from 'react';
import axios from 'axios';
import { handleResponesNotification } from '../helper/accountingHelpers';
import ShowForPermission from 'helpers/showForPermission';
import Switch from "react-switch";
import { markOrdersViolationPaid } from 'services/operationStatement';
import { ReactComponent as EyelIcon } from "assets/images/Tds/eye.svg";

export default function OrdersViolationsList({ violationsList, showAgentViolations, isNoData, getOrderViolations }) {
  const { operationStatement, backOffice, inventory, ordersViolations } = Locale;
  const { locale } = useSBSState();



  const getFilesByOrderId = (id) => {
    const { file, invoice } = violationsList?.find(
      (order) => order.id === id
    );
    return { file, invoice };
  };

  const handlePaymentStatusSwitch = async (e, id) => {
    const res = await markOrdersViolationPaid(id, { payment_status: e });
    if (res?.status === 200) {
      getOrderViolations()
    }
  }



  return (
    <>
      <div className="table-container">
        <table className="table table-update w-100 mb-0">
          <thead>
            <tr>
              {!showAgentViolations &&
                <>
                  <th>{operationStatement.agentName}</th>
                  <th>{operationStatement.country}</th>
                </>
              }
              <th>{operationStatement.dateTime}</th>
              <th>{operationStatement.type}</th>
              <th>{operationStatement.costs}</th>
              <th>{operationStatement.PAX}</th>
              <th>{inventory.messages.paymentStatus}</th>
              <th>{operationStatement.actions}</th>
            </tr>
          </thead>

          <tbody>
            {!isNoData
              ? violationsList.map((item) => {
                return (
                  <tr key={item.id}>
                    {!showAgentViolations &&
                      <>
                        <td className="text-black-900 font-weight-bold">
                          {item.agent.name}
                        </td>
                        <td>
                          <img
                            className="flag"
                            src={`${process.env.REACT_APP_FILEUPLOAD_URL}/fetch/${item.agent.country.flag}`}
                            alt="flag"
                          />
                          <span className="mx-2">{item.agent.country.name[locale]}</span>
                        </td>
                      </>
                    }
                    <td className="date-time">
                      {item.date
                        ? moment(item.date).subtract(3, "hours").format("DD-MM-YYYY HH:mm")
                        : "-"}
                    </td>
                    <td className="text-black-900">{ordersViolations?.[item.violation_type] || item.violation_type}</td>
                    <td className="text-primary font-weight-bold">
                      {item.cost} {item.currency}
                    </td>
                    <td>{item.pax}</td>
                    <td>
                      <Switch
                        onChange={(e) => handlePaymentStatusSwitch(e, item.id)}
                        checked={item?.payment_status}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        height={24}
                        width={44}
                      />
                    </td>

                    <td className="table-actions">

                      <DownloadFiles files={getFilesByOrderId(item.id)} />
                      {item?.payment_status ?
                        <ShowForPermission permission="Manage-Order-Violation">
                          <Link to={`/operations/orders-violations/view/${item.id}`}>
                            <EyelIcon color="#707170" />
                          </Link>
                        </ShowForPermission>
                        :
                        <ShowForPermission permission="Manage-Order-Violation">
                          <Link to={`/operations/orders-violations/edit/${item.id}`}>
                            <EditIcon color="#707170" />
                          </Link>
                        </ShowForPermission>
                      }
                    </td>
                  </tr>
                );
              })
              :
              <tr>
                <td
                  colSpan={showAgentViolations ? 5 : 7}
                  className="text-center"
                  style={{ height: 400 }}
                >
                  {backOffice.noResult}
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>


    </>
  )
}


const DownloadFiles = ({ files }) => {
  const { operationStatement } = Locale;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);


  const handleDownload = async (fileUUID) => {
    const config = {
      method: "GET",
      responseType: "blob",
    };
    const res = await axios.get(`${process.env.REACT_APP_FILEUPLOAD_URL}/fetch/${fileUUID}`, config);
    const blob = new Blob([res.data], {
      type: res.headers["content-type"],
    });

    const objectUrl = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.target = "_blank";
    anchor.href = objectUrl;
    anchor.setAttribute(
      "download",
      `attach-file-${moment().format("DD-MM-YYYY")}.${res.data.type.split("/")[1]}`
    );
    anchor.click();

    handleResponesNotification({ alertType: "success", message: "Downloaded Successfully", title: "Download" })
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle style={{ all: "unset", cursor: "pointer" }}>
        <DocumentDownloadIcon color="#707170" />
      </DropdownToggle>
      <DropdownMenu positionFixed>
        <DropdownItem onClick={() => handleDownload(files.file)}>
          {operationStatement.violationFile}
        </DropdownItem>
        <DropdownItem onClick={() => handleDownload(files.invoice)}>
          {operationStatement.invoiceFile}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};
