import React, { useState } from 'react'

import TextField from 'components/Form/TextField/TextField';
import Pagination from 'components/Pagination';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import SeachIcon from "assets/images/market-place/servicesIcon/searchIcon.png";
import { ReactComponent as OperatingCenterIcon } from "assets/images/umrah-operations/operation-statement-icon.svg";
import { ReactComponent as MoreIcon } from 'assets/images/umrah-operations/more-square.svg'
import { DangerIcon } from 'modules/UmrahOperations/shared/Icons';
import useDebounce from 'hooks/useDebounce';
import { useHistory } from 'react-router-dom';
import Locale from 'translations';


const fetchFlag = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";


export default function AccountingAgentsList({ getAgents, agentsList, meta }) {
  // ** translations
  const { CRM } = Locale;
  
  // ** hooks
  const history = useHistory();

  // ** states
  const [searchTerm, setSearchTerm] = useState(null);
  const [openMenuId, setOpenMenuId] = useState(null);

  const debouncedSearch = useDebounce((text) => getAgents({ search: text }), 300);
  
  // ** functions
  function toggleActionMenu(id) {
    setOpenMenuId(prevId => (prevId === id ? null : id));
  }

  function goTo(page) {
    getAgents({ page })
  };

  return (
    <div className='movement-tab'>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className='col-md-4 col-12 p-0'>
          <TextField
            type="text"
            id="agent-search"
            name="agent-search"
            placeholder="search"
            isImage={true}
            image={SeachIcon}
            prependImage={true}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              debouncedSearch(e.target.value)
            }}
            value={searchTerm}
          />
        </div>
      </div>
      <div className="table-container agent-accounting-table-wrapper">
        <table className="table table-update w-100 mb-0">
          <thead>
            <tr>
              <th scope="col">{Locale.operationAccounting.name}</th>
              <th scope="col">{Locale.operationAccounting.country}</th>
              <th scope="col">{Locale.operationAccounting.operations}</th>
              <th scope="col">{Locale.operationStatement.pax}</th>
              <th scope="col">{Locale.operationAccounting.totalInvoices}</th>
              <th scope="col">{Locale.operationAccounting.outstanding}</th>
              <th scope="col">{Locale.operationAccounting.violations}</th>
              <th scope="col">{Locale.operationAccounting.violationsCost}</th>
              <th scope="col">{Locale.operationAccounting.violationsOutStanding}</th>
              <th scope="col">{Locale.operationAccounting.actions}</th>
            </tr>
          </thead>
          <tbody>

            {agentsList?.length > 0 ?
              agentsList?.map(agent => {
                return (
                  <tr key={agent?.id}>
                    <td>
                      <p className='text-black'>
                        {agent?.name}
                      </p>

                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <img
                          src={agent.country?.flag ? `${fetchFlag}/${agent.country?.flag}` : "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E"}
                          alt={agent.country?.name}
                          className={`rounded-circle ${!agent?.country?.flag ? " border" : ""}`}
                          width={"20px"}
                          height={"20px"}
                          style={{ flex: "0 0 16px" }}
                        />
                        <span className="mx-2">{agent.country?.name}</span>
                      </div>

                    </td>

                    <td>
                      <p className='text-black'>
                        {agent?.operations || "-"}
                      </p>

                    </td>

                    <td>
                      <p className='font-weight-bold text-black'>
                        {agent?.pilgrims || "-"}
                      </p>
                    </td>

                    <td>
                      <p className='font-weight-bold text-black'>
                        {agent?.total_invoices ? `${agent?.total_invoices} ${agent?.currency || 'SAR'}` : "-"}
                      </p>
                    </td>

                    <td>
                      <p className='font-weight-bold text-yellow'>
                        {agent?.outstanding_invoices ? `${agent?.outstanding_invoices} ${agent?.currency || 'SAR'}` : "-"}
                      </p>
                    </td>

                    <td>
                      <p className='font-weight-bold text-red'>
                        {agent?.violations_count || "-"}
                      </p>
                    </td>

                    <td>
                      <p className='font-weight-bold text-black'>
                        {agent?.violation_cost ? `${agent?.violation_cost} ${agent?.currency || 'SAR'} ` : "-"}
                      </p>
                    </td>

                    <td>
                      <p className='font-weight-bold text-black'>
                        {agent?.violations_outstanding ? `${agent?.violations_outstanding} ${agent?.currency || 'SAR'}` : "-"}
                      </p>
                    </td>

                    <td>
                      <div className="table-actions">
                        <Dropdown className="pointer"
                          isOpen={openMenuId === agent.id}
                          toggle={() => toggleActionMenu(agent.id)}
                          end={true}
                        >
                          <DropdownToggle style={{ all: "unset" }}>
                            <MoreIcon />
                          </DropdownToggle>

                          <DropdownMenu className="py-2 px-3" positionFixed end>
                            <span>{Locale.operationAccounting.view}</span>
                            <DropdownItem className="px-1 my-2"
                              onClick={() => {
                                history.push(`/operations/accounting/agent-statement/${agent?.id}`)
                              }}
                            >
                              <OperatingCenterIcon />
                              <span className="mx-1">{Locale.operationAccounting.allOperationStatement}</span>
                            </DropdownItem>

                            <DropdownItem className="px-1 mb-2"
                              onClick={() => {
                                history.push(`/operations/accounting/agent-violations/${agent?.id}`)
                                // handleRowAction({ tab: "violations", agent });
                              }}
                            >
                              <DangerIcon />
                              <span className="mx-1">{Locale.operationAccounting.allViolations}</span>
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>

                    </td>
                  </tr>

                )
              })
              :
              <tr>
                <td colSpan="10">
                  <div className="product-build__product-no-data fullHeight no-result">
                    <h4>{CRM.noResults}</h4>
                  </div>
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>

      <div className='py-3'>
        <Pagination info={meta} goTo={goTo} />
      </div>
    </div>
  )
}
